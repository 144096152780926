import React, { useState, useEffect, useRef } from 'react';
import left from '../images/Left.png';
import deleteimg from '../images/Delete.png';
import editimg from '../images/Edit.png';
import downloadimg from '../images/Downloading Updates.png';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useParams, useNavigate, Link } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import './style.css';
import { Footer } from './components/Footer';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import Fade from '@mui/material/Fade';
import info from '../images/info.png';
import { analytics } from "../firebase/index";
import cross from '../images/Cross.png';
import { logEvent } from "firebase/analytics";

import { useUserAuth } from "../context/UserAuthContext";
import { App } from '@capacitor/app';
import countries from '../utils/countries';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactCountryFlag from 'react-country-flag';
import Form from 'react-bootstrap/Form';



const localizer = momentLocalizer(moment);

export const DocCalender = () => {
  const { ID, user, DocEvents, setDocEvent, loggedIn, AddNewSlotDoc, fetchCompleteSlotDocData, deleteDocSlot, AddNewSingleSlot, updateCompleteDocSlotData, handleAddDocAppointment, generateAppointmentPDF } = useUserAuth();
  
  const [selectedDate, setSelectedDate] = useState(moment());
  const [events, setEvents] = useState([]); // Store the events here
  const [selectedSlot, setSelectedSlot] = useState({
    start: '', 
    end: '', 
    appointmentType: '',
    duration: '',
    days: [],
  });
  const [dummyEvent, setDummyEvent] = useState(null);
  const [addDummyEvent, setAddDummyEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailModalOpen, setDetailIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // Track edit mode
  const [isAddModelOpen, setIsAddModelOpen] = useState(false); // state to control the opening the of Add slot pop up 
  const [oldEvents, setOldEvents] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [result, setResult] = useState();
  const [showOverlappingErrorModal, setShowOverlappingErrorModal] = useState(false);
  const [selectedBookedEvent, setSelectedBookedEvent] = useState(null); // Track the selected "booked-event" event
  const [isInitialRender,setIsInitialRender ] = useState(true);
  const [realUpdating, setRealUpdating] = useState();
  const [updating, setUpdating] = useState();
  const [openInfo, setOpenInfo] = useState(false);
  const [openTypeInfo, setOpenTypeInfo] = useState(false);
  const [warning, setWarning] = useState('');
  const [isAddAppointmentOpen, setIsAddAppointmentOpen] = useState(false); 
  const [patientName, setPatientName] = useState('');
  const [patientNumber, setPatientNumber] = useState(''); 
  const [selectedAppointmentType, setSelectedAppointmentType] = useState([]);
  const [availableAppointmentType, SetAvailableAppointmentType] = useState([]);
  const [appointmentSelectedSlot, setAppointmentSelectedSlot] = useState("");
  const [error, setError] = useState('');
  const [addAppointment, setAddAppointment] = useState(false);
  const [countryCode, setCountryCode] = useState('+91');
  const [searchQuery, setSearchQuery] = useState('');

  
  const [slotForDay, setSlotForDay] = useState('');
  const calendarRef = useRef(null);
  const currentDay = moment();


  const sampleAppointmentTypes = [
    { 
      value: 'checkup',
      label: 'Checkup',
      heading: 'Regular Checkup',
      description: 'Routine checkup for general health assessment.',
    },
    { 
      value: 'consultation',
      label: 'Consultation',
      heading: 'Medical Consultation',
      description: 'Consultation with a doctor regarding specific health concerns.',
    },
    { 
      value: 'procedure',
      label: 'Procedure',
      heading: 'Medical Procedure',
      description: 'Medical procedure or treatment.',
    },
  ];


  const bookedColors = ['#FADB8C', '#FADB8C', '#FADB8C', '#FADB8C'];

  const setLoading = (value) => {
    setIsLoading(value);
    console.log("hey this in changing loader value function ",value, isLoading);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCountries = countries.filter((country) => 
    country.name.toLowerCase().includes(searchQuery.toLowerCase()) || country.code.includes(searchQuery)
  );
  const handleCountryCodeChange = (code) => {
    setCountryCode(code);
    setSearchQuery('');
  };
  

  
  useEffect(() => {
    // Scroll to the top of the page when it loads
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

    
  useEffect(() => {
    console.log('Selected Date:',selectedDate, selectedDate.toDate());
    console.log("current Date:",currentDay, currentDay.toDate());
    setAddAppointment(currentDay.toDate().toISOString().split('T')[0] <= selectedDate.toDate().toISOString().split('T')[0]);
    console.log('Is current date less than or equal to selected date?', 
    currentDay.toDate().toISOString().split('T')[0] <= selectedDate.toDate().toISOString().split('T')[0]);
    // console.log("Logged In data ",loggedIn);
  }, [selectedDate]);

  useEffect(() => {
    // Set the app element for react-modal
    const rootElement = document.getElementById('root');
    Modal.setAppElement(rootElement);
  }, []);

  const handleImageClick = () => {
    navigate('/home');
  };

  useEffect(() => {
    
    
    fetchData();
  }, [result]); // Fetch data on component mount
  
  const fetchData = async () => {
    console.log("Running fetchData...");
    setLoading(true);
    try {
      const completeSlotData = await fetchCompleteSlotDocData();
      console.log("Calendar data: ", completeSlotData);

      if (completeSlotData) {
        const newEvents = [];
        for (const date in completeSlotData) {
          completeSlotData[date].forEach((slot, index) => {
            let isBooked = false;

            try {
              isBooked = slot.ID && slot.ID.startsWith('booked');
            } catch {
              isBooked = false;
            }

            const color = isBooked ? bookedColors[index % bookedColors.length] : undefined;
            const eventTitle = slot.Name || 'Blocked';

            const start = slot.start ? new Date(date + ' ' + slot.start) : null;
            const end = slot.end ? new Date(date + ' ' + slot.end) : null;

            newEvents.push({
              id: newEvents.length + 1,
              ID: `${slot.ID}`,
              title: eventTitle,
              start: start,
              end: end,
              className: isBooked ? 'booked-event' : "not-yetbooked",
              color: color,
              appointmentType: slot.appointmentType || '',
              appointmentStartTime: slot.appointmentStartTime || '',
              day: slot.day || '',
              remainingMinutes: slot.remainingMinutes || '',
              slotDuration: slot.slotDuration || '',
              patientName: slot.patientname || '', 
              patientNumber: slot.patientnumber || '',
              orderID: slot.orderid || ''
            });
          });
        }
        
        console.log("Calendar events: ", newEvents);
        localStorage.setItem('DocCalenderEvents', JSON.stringify(newEvents));
        setEvents(newEvents);
        // setDocEvent(newEvents);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const handleAppStateChange = ({ isActive }) => {
      if (isActive) {
        console.log("fetching data again ")
        fetchData();
      }
    };

    App.addListener('appStateChange', handleAppStateChange);

    return () => {
      App.removeAllListeners();
    };
  }, []); 

  function formatDateTime(input) {
    const match = input.match(/booked_(\d{2})\/(\d{2})\/(\d{4})_(\d{2}:\d{2})/);
    if (match) {
      const [_, day, month, year, time] = match;
      const [hour, minute] = time.split(':');

      const inputDate = new Date(year, month - 1, day, hour, minute);
      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      return new Intl.DateTimeFormat('en-US', options).format(inputDate);
    } else {
      // Handle invalid input
      return "Invalid Date/Time";
    }
  }

  function formatTime(timeStr) {
    const [hours, minutes] = timeStr.split(':');
    let formattedTime;
  
    if (parseInt(hours, 10) < 12) {
      formattedTime = `${hours}:${minutes} AM`;
    } else {
      formattedTime = `${parseInt(hours, 10) - 12}:${minutes} PM`;
    }
  
    // Handle 12:00 PM and 12:00 AM
    if (parseInt(hours, 10) === 0) {
      formattedTime = `12:${minutes} AM`;
    } else if (parseInt(hours, 10) === 12) {
      formattedTime = `12:${minutes} PM`;
    }
  
    return formattedTime;
  }
  

  const generateDayBlocks = () => {
    const days = [];
    const currentDate = moment();
    const startDate = currentDate.clone().subtract(3, 'days');
    const endDate = currentDate.clone().add(30, 'days');
    const dateRange = [];
    while (startDate.isBefore(endDate)) {
      dateRange.push(startDate.clone());
      startDate.add(1, 'day');
    }
    // console.log("date Range ", dateRange);
 
    const selectedMonth = selectedDate.format('MMMM');
    return (
      <>
        <div className="selected-month">{selectedMonth}</div>
        {dateRange.map((date) => {
          const isSelectedDay = selectedDate.isSame(date, 'day');
          return (
            <div
              key={date.format('YYYY-MM-DD')}
              className={`day-block`}
              onClick={() => {
                console.log("on clicking", date.format('YYYY-MM-DD'));
                setSelectedDate(date.clone());
                logEvent(analytics, 'py_Calender_day_click', {
                  Date: new Date().toLocaleString(),
                  ID,
                  clickedDate: date.format('DD/MM/YYYY'),
                });
              }}
            >
              <div style={{ marginBottom: "10%" }}>
                <div className='day-block2'>{date.format('ddd')}</div>
              </div>
              <div className={`date-block ${isSelectedDay ? 'selected-day-date' : ''}`}>
                <div className={`${isSelectedDay ? 'selected-day-date-inner' : 'date-block-inner '}`}>{date.format('DD')}</div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const handleBookedEventClick = (event) => {
    if (event.className === 'booked-event') {
      setSelectedBookedEvent(event);
      setDetailIsModalOpen(true);
    }
  };



  const handleSelectSlot = ({ start, end }) => {
    // Set the selected slot
  
    const currentDate = moment();
    const selectedDays = [moment(start).format('ddd')]; // Get the day of the week for the start time
  
    // Check if the selected date is today or before today
    if (moment(start).isSameOrBefore(currentDate, 'day')) {
      // Selected date is today or before today, disable further actions
      // alert("You can't select today or a past date.");
    } else {
      setSelectedSlot({ start, end, days: selectedDays });
      // Create a new event and add it to the events array
      const newEvent = {
        id: events.length + 1,
        title: 'Blocked',
        start,
        end,
        days: selectedDays,
      };
      setEvents([...events, newEvent]);
      setOldEvents(events);
      setDummyEvent(newEvent);
      setAddDummyEvent(events);
      setIsAddModelOpen(true);
      setSelectedSlot(newEvent);
  
      console.log('Slot selected:', newEvent);
    }
  };
  
  const handleEditEvent = (event) => {
    setSelectedEvent(event);
    console.log("this is what u have to operate on ", event.start.toString().substring(0,16),typeof(event.start));
    setIsEditMode(true); // Enter edit mode
    setIsModalOpen(true);
    logEvent(analytics, 'py_click_edit_icon_on_Event_slot', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(event),
      "py_log" : "In calender Clicked on Edit icon on Event Slot"
    }); 
  };

  const handleUpdateSlot = async (event) => {
    console.log("this is handle Delete Event ",event);
    event.preventDefault();
    console.log("Selected Event ",selectedEvent);

    logEvent(analytics, 'py_updating_event_in_doc_calender', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(selectedEvent),
      "py_log" : "Updating the given Event"
    }); 

    if(selectedEvent.start >= selectedEvent.end){
      alert("not possible ");
      setSelectedEvent(null);
      setSelectedSlot(null);
      setIsModalOpen(false);
      setIsEditMode(false); // Exit edit mode
      return;
    }
    

    if (selectedEvent) {
      setLoading(true);
      console.log("selected slot ",selectedEvent);
      console.log("this is before removing ",events);
      var updatedEvents = events.filter((event) => event.ID !== selectedEvent.ID);
      setEvents(updatedEvents);
      console.log("this is after removing ", updatedEvents);
      // setIsEditMode(false); // Exit edit mode
      // setSelectedSlot(selectedEvent); 
      
      const newEvent = {
        id: events.length + 1,
        title: 'Blocked',
        start: selectedEvent.start,
        end: selectedEvent.end,
        className: "slot-under-process",
      };
      const overlappingEvents = updatedEvents.filter((event) => {
        return (
          event.id !== newEvent.id &&
          ((new Date(newEvent.start) < new Date(event.end) &&
            new Date(newEvent.end) > new Date(event.start)) ||
          (new Date(event.start) < new Date(newEvent.end) &&
            new Date(event.end) > new Date(newEvent.start)))
        );
      });
      
      if (overlappingEvents.length > 0) {
        console.log("Overlapping events detected in updatedEvents");
        const eventsToBeRemoved = [];
      
        overlappingEvents.forEach((event) => {
          if (newEvent.start <= event.start && newEvent.end >= event.end) {
            // The new event completely covers the overlapping event
            eventsToBeRemoved.push(event);
            console.log("The new event completely covers the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start <= event.start && newEvent.end < event.end) {
            // The new event partially covers the beginning of the overlapping event
            event.start = newEvent.start;
            console.log("The new event partially covers the beginning of the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start > event.start && newEvent.end >= event.end) {
            // The new event partially covers the end of the overlapping event
            event.end = newEvent.end;
            console.log(" The new event partially covers the end of the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start > event.start && newEvent.end < event.end) {
            const newEventPart1 = {
              ...event,
              id: updatedEvents.length + 1, // Assign a new ID
              end: newEvent.start,
            };
            const newEventPart2 = {
              ...event,
              id: updatedEvents.length + 2, // Assign a new ID
              start: newEvent.end,
            };
            updatedEvents.push(newEventPart1, newEventPart2);
            eventsToBeRemoved.push(event);
            console.log(" The new event partially covers the end of the overlapping event ",event,eventsToBeRemoved);
          }
        });
      
        // Remove events that are fully covered by the new event
        updatedEvents = updatedEvents.filter((event) => !eventsToBeRemoved.includes(event));
      
        console.log("Merged overlapping events in updatedEvents:", overlappingEvents);
      } else {
        // No overlaps in updatedEvents, add the new event to updatedEvents
        console.log("No overlap detected in updatedEvents");
        updatedEvents.push(newEvent);
      }
      
      
      
      
      setEvents(updatedEvents);
      try {
        setLoading(true);
        setRealUpdating(Math.random());

      } catch (error) {
          console.error("Error adding New Slot in the Slot", error);
          // Handle the error, e.g., show an error message to the user
      }
      finally {
        setLoading(false); // Set loading to false after the call
      }
      // setSelectedEvent(null);
      // setSelectedSlot(null);
      setIsModalOpen(false);
      
      
      setLoading(false); // Set loading to false after the call
      
    }
  };

  useEffect(() => {
    const updateSlotToBackend = async () => {
      if (selectedEvent && !isLoading) {
        setIsEditMode(true);
        try {
          console.log("the value of selected event ",selectedEvent);
          console.log("Data Adding ...");
          const added = await updateCompleteDocSlotData(selectedEvent);
          setResult(Math.random());
          console.log("value of added ",added)
          
        } catch (error) {
          console.error("Error adding New Slot in the Slot", error);
        } 
      }
    setSelectedSlot(null);
    setIsEditMode(false);

    };
  
    updateSlotToBackend();
  }, [realUpdating]);



  const handleDeleteEvent = async (event) => {
    // console.log("this is handle Delete Event ",JSON.stringify(event));
    event.preventDefault();
    // Handle the deletion of the selected event
    // console.log("Selected Event ",JSON.stringify(selectedEvent.ID));
    logEvent(analytics, 'py_click_Delete_icon_on_Event_slot', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(selectedEvent),
      "py_log" : "In calender Clicked on Edit icon on Event Slot and Deleting the above Event"
    }); 

    if (selectedEvent) {
      setLoading(true);
      const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
      setEvents(updatedEvents);
      setSelectedEvent(null);
      setSelectedSlot(null);
      setIsModalOpen(false);
      setIsEditMode(false); 
      setLoading(false); // Set loading to false after the call
      const result = await deleteDocSlot(selectedEvent);
      console.log("result in calender ", result);
      if(result === 1){
        console.log("Slot Deleted Successfully");
      }
      else{
        console.log("Error Deleting ")
      }
      
    }
  };


  useEffect(() => {
    const updateSlotToBackend = async () => {
      if (selectedSlot && !isLoading) {
        setIsEditMode(true);
        try {
          console.log("the value of selected event ",selectedSlot);
          // const result = await deleteDocSlot(selectedEvent);
          console.log("result in calender ", result);
          // console.log("Slot Deleted Successfully");
          console.log("Data Adding ...");
          const added = await AddNewSlotDoc(selectedSlot);
          setResult(Math.random());
          console.log("value of added ",added)
          if(added===0){
            console.log("it is coming added ==== 0 ")
            const updatedEvents = events.filter((event) => (event!=dummyEvent));
            setEvents(updatedEvents);
            setShowOverlappingErrorModal(true);
          }

        } catch (error) {
          console.error("Error adding New Slot in the Slot", error);
        } 
      }
    setSelectedSlot(null);
    setIsEditMode(false);

    };
  
    updateSlotToBackend();
  }, [updating]);


  const handleUpdatedSlot = async (event) => {
    console.log("this is handle Delete Event ",event);
    event.preventDefault();
    console.log("Selected Event ",selectedSlot);
    if (!selectedSlot.appointmentType?.trim() || !selectedSlot.duration?.trim()) {
      setWarning('Type and Duration cannot be empty.');
      return;
    }
    logEvent(analytics, 'py_updating_event_in_doc_calender', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(selectedSlot),
      "py_log" : "Updating the given Event"
    }); 

    if(selectedSlot.start >= selectedSlot.end){
      alert("not possible ");
      setSelectedEvent(null);
      setSelectedSlot(null);
      setIsModalOpen(false);
      setIsEditMode(false); // Exit edit mode
      return;
    }
    

    if (selectedSlot) {
      setLoading(true);
      console.log("selected slot ",selectedSlot);
      console.log("this is before removing ",events);
      var updatedEvents = events.filter((event) => event.ID !== selectedSlot.ID);
      setEvents(updatedEvents);
      console.log("this is after removing ", updatedEvents);
      // setIsEditMode(false); // Exit edit mode
      // setSelectedSlot(selectedEvent); 
      
      const newEvent = {
        id: events.length + 1,
        title: 'Blocked',
        start: selectedSlot.start,
        end: selectedSlot.end,
        className: "slot-under-process",
      };
      const overlappingEvents = updatedEvents.filter((event) => {
        return (
          event.id !== newEvent.id &&
          ((new Date(newEvent.start) < new Date(event.end) &&
            new Date(newEvent.end) > new Date(event.start)) ||
          (new Date(event.start) < new Date(newEvent.end) &&
            new Date(event.end) > new Date(newEvent.start)))
        );
      });
      
      if (overlappingEvents.length > 0) {
        console.log("Overlapping events detected in updatedEvents");
        const eventsToBeRemoved = [];
      
        overlappingEvents.forEach((event) => {
          if (newEvent.start <= event.start && newEvent.end >= event.end) {
            // The new event completely covers the overlapping event
            eventsToBeRemoved.push(event);
            console.log("The new event completely covers the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start <= event.start && newEvent.end < event.end) {
            // The new event partially covers the beginning of the overlapping event
            event.start = newEvent.start;
            console.log("The new event partially covers the beginning of the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start > event.start && newEvent.end >= event.end) {
            // The new event partially covers the end of the overlapping event
            event.end = newEvent.end;
            console.log(" The new event partially covers the end of the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start > event.start && newEvent.end < event.end) {
            const newEventPart1 = {
              ...event,
              id: updatedEvents.length + 1, // Assign a new ID
              end: newEvent.start,
            };
            const newEventPart2 = {
              ...event,
              id: updatedEvents.length + 2, // Assign a new ID
              start: newEvent.end,
            };
            updatedEvents.push(newEventPart1, newEventPart2);
            eventsToBeRemoved.push(event);
            console.log(" The new event partially covers the end of the overlapping event ",event,eventsToBeRemoved);
          }
        });
      
        // Remove events that are fully covered by the new event
        updatedEvents = updatedEvents.filter((event) => !eventsToBeRemoved.includes(event));
      
        console.log("Merged overlapping events in updatedEvents:", overlappingEvents);
      } else {
        // No overlaps in updatedEvents, add the new event to updatedEvents
        console.log("No overlap detected in updatedEvents");
        updatedEvents.push(newEvent);
      }
      
      
      
      
      setEvents(updatedEvents);
      try {
        setLoading(true);
        setUpdating(Math.random());

      } catch (error) {
          console.error("Error adding New Slot in the Slot", error);
          // Handle the error, e.g., show an error message to the user
      }
      finally {
        setLoading(false); // Set loading to false after the call
      }
      // setSelectedEvent(null);
      // setSelectedSlot(null);
      setIsModalOpen(false);
      setIsAddModelOpen(false);
      
      
      setLoading(false); // Set loading to false after the call
      
    }
  };






  const handleCancelSlot = () => {
    // Check if selectedSlot is not null
    if (selectedSlot) {
      // Handle the cancellation of the selected slot here
      console.log('Cancelled Slot:', selectedSlot);
      
      const updatedEvents = events.filter((event) => (
        event.start !== selectedSlot.start && event.end !== selectedSlot.end
      ));
      setEvents(updatedEvents);
      
      if (isEditMode) {
        // If in edit mode, reset the selected event to its original state
        setSelectedEvent(null); // Clear selected event
        setSelectedSlot(null); // Clear selected slot
        setIsModalOpen(false); // Close the modal
        setIsEditMode(false); // Exit edit mode
      } else {
        // If not in edit mode, simply clear the selected slot
        setSelectedSlot(null);
        setIsModalOpen(false);
      }
    } else {
      // Handle the case where selectedSlot is null (optional)
      console.log('No slot selected to cancel.');
      setIsModalOpen(false);
    }
  };
  
  const handleDeleteSlot = () => {
    // Handle the deletion of the selected slot here
    if (selectedEvent) {
      const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
      setEvents(updatedEvents);
      setSelectedEvent(null);
      setSelectedSlot(null);
      setIsModalOpen(false);
      setIsEditMode(false); // Exit edit mode
    }
  };

  const DownloadSlots = async() =>{
    setLoading(true);
    console.log("this is the date selected ",selectedDate);

    try{
      console.log("this is the slots for that day ",)

      const filteredEvents = events.filter(event => {
        const eventStartDate = new Date(event.start);
        console.log("this is event start date ",eventStartDate, selectedDate);
        if(eventStartDate!='Invalid Date' && selectedDate){
          const eventDate = eventStartDate.toISOString().split('T')[0];
          const selectedDateString = selectedDate.toISOString().split('T')[0];

          console.log("event date and ID ", eventDate, selectedDateString, event.ID);

          return eventDate === selectedDateString && event.ID.startsWith("booked");
        }
        
      });
      
      console.log("this is the final data ",filteredEvents);
      const link = await generateAppointmentPDF(filteredEvents, selectedDate);
      window.open(link, "_blank");
      setLoading(false);
    }catch(e){
      const link = await generateAppointmentPDF([], selectedDate);
      window.open(link, "_blank");
      setLoading(false);
    }
    

  }

  const now = new Date(); // Get the current date and time
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0); // Set the start time to 8:00 AM
  const max = new Date(now.getFullYear(), now.getMonth() + 30, now.getDate(), 8, 0, 0);


  const handleAddManualAppointment = () => {
    setIsAddAppointmentOpen(true); // Open the Add slot pop-up when plus button is clicked
    // console.log("this is event ",events);
    const appointmentsForSelectedDate = events.filter(appointment => {
      const appointmentDate = new Date(appointment.start);
      appointmentDate.setHours(0, 0, 0, 0);
      const selectedDateAsDate = selectedDate.toDate();
      selectedDateAsDate.setHours(0, 0, 0, 0);
      // console.log("printing dates ",appointmentDate.toDateString(),selectedDateAsDate.toDateString());
      return appointmentDate.toDateString() === selectedDateAsDate.toDateString() && !appointment.className.startsWith('booked');
    });
    const availableAppointmentTypes = [...new Set(appointmentsForSelectedDate.map(appointment => appointment.appointmentType))];
    setSlotForDay(appointmentsForSelectedDate);
    SetAvailableAppointmentType(availableAppointmentTypes);
    console.log("this is appointment types ",appointmentsForSelectedDate,availableAppointmentTypes);
  };

  // Function to handle adding appointments
  const handleAddAppointment = async() => {
    // Add your logic here to save the patient's information and close the modal
    if (!patientName || !patientNumber || !appointmentSelectedSlot) {
      setError('Please fill in all fields');
      return;
    }

    // Validate phone number length
    let patientNumberwithcode = countryCode+patientNumber;
    setIsLoading(true);
    // For example:
    console.log('Patient Name:', patientName);
    console.log('Patient Number:', patientNumberwithcode);
    console.log("slot id ", appointmentSelectedSlot)

    try{
      console.log("Result before calling ",result);
      const response = await handleAddDocAppointment(patientName,patientNumberwithcode,appointmentSelectedSlot);
      setResult(Math.random());
      console.log("THis is the response ",response,result);
      
    }catch(e){
      console.log("Error while adding slots appointment slot",e);
    }

    // Close the modal after saving the information
    setIsLoading(false);
    setIsAddAppointmentOpen(false);
    setPatientName('');
    setPatientNumber('');
    setAppointmentSelectedSlot('');
  };

  const handleDelete = async (orderID) => {
    setIsLoading(true);
    try {
      // Make POST request to deleteAppointment API
      const response = await fetch('https://n5ec4bsdk9.execute-api.ap-south-1.amazonaws.com/dev/deleteAppointment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
        },
        body: JSON.stringify({ orderId: orderID })
      });
  
      // Check if the request was successful
      if (response.ok) {
        // Appointment deleted successfully
        console.log('Appointment deleted successfully');
        // Optionally, you can perform additional actions here after successful deletion
      } else {
        // If the request was not successful, throw an error
        throw new Error('Failed to delete appointment');
      }
    } catch (error) {
      // Handle any errors that occurred during the deletion process
      console.error('Error deleting appointment:', error.message);
      // Optionally, you can show an error message to the user or perform other error handling actions
    }
    setResult(Math.random());
    setIsAddAppointmentOpen(false);
    setDetailIsModalOpen(false);
    setIsLoading(false);
  };
  


  return (
    <div>

      <div className="top-container" style={{ paddingTop: "8%"}}>
        <div style={{ marginLeft: "5%", display:"flex", justifyContent:"space-between", marginRight:"5%" }}>
          <div>
          <img
            className="AP-back-image"
            src={left}
            alt="back"
            onClick={handleImageClick}
          />
          &nbsp; &nbsp;
          <span className="calender-text">Calendar</span>
          </div>
          <div>
            <img src={downloadimg} alt='download' className='AP-back-image' onClick={DownloadSlots}/>
          </div>
          
          
        </div>
        
        <div className="day-block-container">
        <br />
        {generateDayBlocks()}
      </div>
      </div>

      
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      )}
      <Calendar
        localizer={localizer}
        defaultDate={selectedDate}
        date={selectedDate} 
        defaultView="day"
        views={['day']}
        style={{ height: '60vh' }}
        events={events}
        selectable
        onSelectSlot={handleSelectSlot}                                        // uncomment this if u want calender to be selected 
        onNavigate={(newDate) => setSelectedDate(moment(newDate))}
        components={{
          timeSlotWrapper: (props) => (
            <div className="my-calendar-text">{props.children}</div>
          ),
          event: ({ event }) => (
            <div
              className={`my-calendar-text ${event.className}`}
              style={{ background: event.color }}
              onClick={() => handleBookedEventClick(event)} // Handle click on "booked-event"
            >
              {event.title !== 'Blocked' &&(
                <div>{event.title}</div>
              )}
              
              {new Date(event.start) < new Date() || event.className ==="not-yetbooked" && ( // Show edit icon for non-booked events need to go through this again 


              <div className="calender-edit-icon-container">
                <img
                  className='calender-edit-icon'
                  src={editimg}
                  onClick={() => handleEditEvent(event)} // Pass the event to the handler
                />
              </div>
                
              )}
              {event.className ==="slot-under-process" && ( // Show edit icon for non-booked events need to go through this again 


              <div className="calender-edit-icon-container">  
                <img
                  className='calender-edit-icon'
                  src={editimg}
                  // onClick={() => handleEditEvent(event)} // Pass the event to the handler
                />
              </div>
                
              )}
            </div>
          ),
        }}
        dayPropGetter={() => ({ className: 'custom-day-slot' })}
        toolbar={false}
        min={today} // Set the minimum visible date and time
        // max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20, 0, 0)} // Set the maximum visible date and time
        draggable={false}
      />
      <br /> <br /> <br /> <br /> <br />

      {selectedSlot && (
        <div className=''>
          <div>
            <Modal
              isOpen={isAddModelOpen}
              onRequestClose={() => {
                setIsAddModelOpen(false);
                setSelectedEvent(null);
                setEvents(oldEvents);
                setWarning('');
                setSelectedSlot({
                  start: '', 
                  end: '', 
                  appointmentType: '',
                  duration: '',
                  days: [],
                });
              }}
              style={{
                overlay: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
                  transition: 'background 0.5s ease',
                  zIndex : '2',
                  
                },
                content: {
                  position: 'relative',
                  top: 'auto',
                  left: 'auto',
                  right: 'auto',
                  bottom: 'auto',
                  border: 'none', // Remove border
                  background: '#fff', // Background color of the modal
                  padding: '20px', // Adjust padding as needed
                  width: '300px' 
                },

              }}
            >
              <div>
                <form>
                  <div className='calender-edit-content'>
                    <input
                      className='calender-edit-input'
                      type="time"
                      name="start"
                      value={moment(selectedSlot.start).format('HH:mm')}
                      onChange={(e) =>
                        setSelectedSlot((prevSelectedEvent) => {
                          const newStartTime = moment(e.target.value, 'HH:mm');
                          const newStartDateTime = moment(prevSelectedEvent.start)
                            .set('hour', newStartTime.hour())
                            .set('minute', newStartTime.minute());
                          return {
                            ...prevSelectedEvent,
                            start: newStartDateTime.toDate(),
                          };
                        })
                      }
                    />
                    <input
                      className='calender-edit-input'
                      type="time"
                      name="end"
                      value={moment(selectedSlot.end).format('HH:mm')}
                      onChange={(e) =>
                        setSelectedSlot((prevSelectedEvent) => {
                          const newEndTime = moment(e.target.value, 'HH:mm');
                          const newEndDateTime = moment(prevSelectedEvent.start)
                            .set('hour', newEndTime.hour())
                            .set('minute', newEndTime.minute());
                          return {
                            ...prevSelectedEvent,
                            end: newEndDateTime.toDate(),
                          };
                        })
                      }
                    />
                  </div>
                  <div className='calender-edit-content'>
                    <div style={{ width: "50%" }}>
                      <label style={{ fontFamily: "Rajdhani" }}>
                        Type &nbsp;
                        <img src={info} alt="" style={{height:"15px"}} onClick={() => setOpenTypeInfo(!openTypeInfo)}/>

                      </label>
                      <div>
                        <Fade in={openTypeInfo}>
                        <div
                          style={{
                            position: 'fixed',
                            top: '', // Adjust as needed
                            // width: '50%',
                            fontFamily: "Rajdhani",
                            fontWeight: "400",
                            fontSize: "80%",
                            zIndex:"80",
                            // border:" 1px solid black",
                            borderRadius: "5px",
                            backgroundColor:"#def1f7",
                            color:"#1c657d",
                            padding:"5px",
                          }}
                        >
                            Enter the Type of the appointment
                          </div>
                        </Fade>
                      </div>
                      <input
                        type="text"
                        name="appointmentType"
                        className='calender-edit-input'
                        value={selectedSlot.appointmentType || ''} // Default value if undefined
                        maxLength={22} // Set the maximum length to 22 characters
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove special characters and spaces
                          setSelectedSlot((prevSelectedEvent) => ({
                            ...prevSelectedEvent,
                            appointmentType: value,
                          }));
                        }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <label style={{ fontFamily: "Rajdhani" }}>
                        Duration &nbsp;
                        <img src={info} alt="" style={{height:"15px"}}  onClick={() => setOpenInfo(!openInfo)}/>
                      </label>
                      <div>
                        <Fade in={openInfo} timeout={500}>
                        <div
                          style={{
                            position: 'fixed',
                            top: '', // Adjust as needed
                            // width: '40%',
                            fontFamily: "Rajdhani",
                            fontWeight: "400",
                            fontSize: "80%",
                            zIndex:"80",
                            // border:" 1px solid black",
                            borderRadius: "5px",
                            backgroundColor:"#def1f7",
                            color:"#1c657d",
                            padding:"5px",
                          }}
                        >
                            Enter the duration of the appointment in minutes
                          </div>
                        </Fade>
                      </div>
                      <input
                        type="number"
                        name="duration"
                        className='calender-edit-input'
                        placeholder="Minutes"
                        value={selectedSlot.duration || ''}
                        onChange={(e) =>
                          setSelectedSlot((prevSelectedEvent) => ({
                            ...prevSelectedEvent,
                            duration: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  {warning && <div style={{ color: 'red', fontFamily: "Rajdhani", fontWeight: "400", fontWeight:"70%" }}>{warning}</div>}
                                    

                  <div>
                    <label style={{ fontFamily: "Rajdhani" }}>Select Weekdays</label>
                    <div className="selectable-days">
                      {['Mon', 'Tue', 'Wed', 'Thu'].map((day) => (
                        <div
                          key={day}
                          className={`day-option ${selectedSlot.days.includes(day) ? 'selecteddays' : ''}`}
                          onClick={() =>
                            setSelectedSlot((prevSelectedEvent) => ({
                              ...prevSelectedEvent,
                              days: prevSelectedEvent.days.includes(day)
                                ? prevSelectedEvent.days.filter((selectedDay) => selectedDay !== day)
                                : [...prevSelectedEvent.days, day],
                            }))
                          }
                        >
                          {day}
                        </div>
                      ))}
                    </div>
                    <div className="selectable-days">
                      {['Fri', 'Sat', 'Sun'].map((day) => (
                        <div
                          key={day}
                          className={`day-option ${selectedSlot.days.includes(day) ? 'selecteddays' : ''}`}
                          onClick={() =>
                            setSelectedSlot((prevSelectedEvent) => ({
                              ...prevSelectedEvent,
                              days: prevSelectedEvent.days.includes(day)
                                ? prevSelectedEvent.days.filter((selectedDay) => selectedDay !== day)
                                : [...prevSelectedEvent.days, day],
                            }))
                          }
                        >
                          {day}
                        </div>
                      ))}
                    </div>
                  </div>
                  <br />
                  <div className='AP-button3' type="button" onClick={handleUpdatedSlot}>
                    <div className='inner-AP-button'>
                      <div className='AP-text1'>SAVE</div>
                    </div>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      )}

      <Footer />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setSelectedEvent(null);
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease',
            zIndex : '2',
            
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '300px' 
          },
        }}
      >
        {selectedEvent && (
          <div>
            <form>
              <div className='calender-edit-header'>
                <div className='calender-edit'>
                  {selectedEvent.start.toString().substring(0,16)}
                </div>
                <img 
                  className='AP-back-image'
                  src={deleteimg}
                  alt="back"
                  type="button" onClick={handleDeleteEvent}
                />
              </div>
              <div className='calender-edit-content'>
                <label className='calender-edit-label' style={{width:"50%"}}>Start</label>
                <input
                  className='calender-edit-input'
                  type="time"
                  name="start"
                  value={moment(selectedEvent.start).format('HH:mm')}
                  onChange={(e) =>
                    setSelectedEvent((prevSelectedEvent) => {
                      const newStartTime = moment(e.target.value, 'HH:mm');
                      const newStartDateTime = moment(prevSelectedEvent.start)
                        .set('hour', newStartTime.hour())
                        .set('minute', newStartTime.minute());
                
                      return {
                        ...prevSelectedEvent,
                        start: newStartDateTime.toDate(),
                      };
                    })
                  }
                />
              </div>
              <div className='calender-edit-content'>
                <label  className='calender-edit-label' style={{width:"50%"}}>End</label>
                <input
                  className='calender-edit-input'
                  type="time"
                  name="end"
                  value={moment(selectedEvent.end).format('HH:mm')}
                  onChange={(e) =>
                    setSelectedEvent((prevSelectedEvent) => {
                      const newEndTime = moment(e.target.value, 'HH:mm');
                      const newEndDateTime = moment(prevSelectedEvent.start)
                        .set('hour', newEndTime.hour())
                        .set('minute', newEndTime.minute());
                      
                      return {
                        ...prevSelectedEvent,
                        end: newEndDateTime.toDate(),
                      };
                    })
                  }
                />
              </div>
              <div className='AP-button3' type="button" onClick={handleUpdateSlot}>
                <div className='inner-AP-button'>
                  <div className='AP-text1'>SAVE</div>
                </div>
              </div>
              
            </form>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isDetailModalOpen}
        onRequestClose={() => {
          setDetailIsModalOpen(false);
          setSelectedBookedEvent(null);
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease',
            zIndex : '2',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '300px',
            // height: '120px', 
          },
        }}
      >
        {selectedBookedEvent && (
          <div>
            <div className='RCmodelheader'>
              <div className='RCmodelheadertext' style={{fontSize:"120%", fontWeight:"800"}}>
                {selectedBookedEvent.patientName} 
              </div>
              <div className='RCmodelheaderbtncontainer'>
                <div>
                  <img 
                    className='RCmodelheaderbtnimage'
                    src={cross}
                    alt="back"
                    style={{ marginLeft:"5%", height:"25px"}}
                    onClick={() => {
                      setDetailIsModalOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
            
            <div  className='APpage-text1' >
              <div style={{display:"inline-flex"}}>
                <b style={{paddingRight:"5px"}}>Type of Appointment:</b>{selectedBookedEvent.appointmentType} <br />
              </div>
              <b>Time : </b>  {formatTime(selectedBookedEvent.appointmentStartTime)} <br />
              <b>Duration </b>: {selectedBookedEvent.slotDuration} mins <br />
              <b>Patient Name</b>: {selectedBookedEvent.patientName} <br />
              <b>Patient Number </b>: {selectedBookedEvent.patientNumber} <br />
              {/* <b>Patient Number </b>: {selectedBookedEvent.orderID} <br /> */}

               {/* Video Consultation Scheduled <br />
              {JSON.stringify(selectedBookedEvent)}  */}
            </div>
            <div className='RCmodelheaderbtncontainer' style={{display:"flex",justifyContent:"right"}}>
                <div>
                <img
                  src={deleteimg}
                  alt="delete"
                  style={{ height: "25px", cursor: "pointer" }}
                  onClick={() => handleDelete(selectedBookedEvent.orderID)}
                />
                </div>
              </div>
          </div>
        )}
      </Modal>

      {/* Inside the return statement of DocCalendar component */}
      <div className="add-appointment-button" onClick={handleAddManualAppointment}>
    <button
        className="plus-button"
        disabled = {!addAppointment}
    >
            <span className="plus-symbol">+</span> 
        </button>
    </div>

      <Modal
        isOpen={isAddAppointmentOpen}
        onRequestClose={() => {
          setIsAddAppointmentOpen(false);
          // setSelectedEvent(null);
          // setEvents(oldEvents);
          // setWarning('');
          // setSelectedSlot({
          //   start: '', 
          //   end: '', 
          //   appointmentType: '',
          //   duration: '',
          //   days: [],
          // });
          setSelectedAppointmentType("");
          setAppointmentSelectedSlot("")
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease',
            zIndex: '2',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '400px',
            borderRadius:"15px"
          },
        }}
      >
        <form style={{ fontFamily: "Rajdhani", fontWeight:"600" }}>
        {availableAppointmentType && availableAppointmentType.length > 0 ? (
          <>
            <div >
                <label style={{fontSize:"18px"}}>Name:</label> <br />
                <input
                  type="text"
                  name="patientName"
                  className='calender-edit-input'
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  style={{fontSize:"18px"}}
                />
              </div>
              <div>
                <label style={{fontSize:"18px"}}>Number:</label> <br />
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', paddingLeft: '2%', fontWeight: '650', paddingRight: '2%', justifyContent: 'space-between' }}>
                  <Dropdown onSelect={handleCountryCodeChange} style={{ marginRight: '10px', fontWeight: '650', fontSize: '120%', height:"48px" }}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" className="custom-dropdown-toggle">
                      <ReactCountryFlag countryCode={countries.find(country => country.code === countryCode)?.countryCode} svg style={{ marginLeft: '0px', marginRight: '10px', borderRadius:"100%",transform: 'scale(1.7)' }} /> 
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-animated">
                      <Form.Control 
                        type="text" 
                        placeholder="Search country code or name" 
                        value={searchQuery} 
                        onChange={handleSearchChange} 
                        style={{ margin: '5%', width:"90%" }}
                      />
                      {filteredCountries.map((country, index) => (
                        <>
                          <Dropdown.Item key={country.code} eventKey={country.code}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                              <div style={{marginRight: "10px"}}>
                                <ReactCountryFlag countryCode={country.countryCode} svg /> {country.name}
                              </div>
                              <div>{country.code}</div>
                            </div>
                          </Dropdown.Item>
                          {index !== filteredCountries.length - 1 && <hr style={{ margin: '0 8px', border: 'none', borderBottom: '2px solid #ccc' }} />}
                        </>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <div style={{ position: 'relative', width: '80%' }}>
                    <span style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#000', fontSize:"20px", fontWeight:"600" }}>{countryCode}</span>
                    <Form.Control
                      type="tel"
                      className="calender-edit-input"
                      value={patientNumber}
                      onChange={(e)=>setPatientNumber(e.target.value)}
                      style={{ paddingLeft: '70px', fontWeight: '600', fontSize: '120%', backgroundColor: '#F6F6F6', border: '0.93px solid #E4E4E4' }}
                      placeholder="Enter mobile number"
                    />
                  </div>
                </div>
              </div>
          
              <div>
                <label style={{fontSize:"18px"}}>Select Appointment Type:</label> <br />
                <select
                  className='calender-edit-input'
                  value={selectedAppointmentType}
                  onChange={(e) => {setSelectedAppointmentType(e.target.value);
                    setAppointmentSelectedSlot('');}}
                    style={{fontSize:"18px"}}
                >
                  <option value='' disabled>Select Appointment Type</option>
                  {availableAppointmentType && availableAppointmentType.length > 0 && availableAppointmentType.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label style={{fontSize:"18px"}}>Select Slot:</label> <br />
                <select
                  className='calender-edit-input'
                  value={appointmentSelectedSlot}
                  onChange={(e) => {
                    setAppointmentSelectedSlot(e.target.value);
                    console.log('Selected Appointment Type:', e.target.value);
                  }}
                  style={{fontSize:"18px"}}
                >
                  <option value='' disabled>Select Slot</option>
                  {slotForDay && slotForDay.length > 0 && slotForDay
                  .filter(slot => slot.appointmentType === selectedAppointmentType)
                  .map((slot, index) => (
                    <option key={index} value={slot.ID}>
                      {slot.appointmentStartTime && `${((slot.appointmentStartTime.split(':')[0] % 12) || 12)}:${slot.appointmentStartTime.split(':')[1]} ${(slot.appointmentStartTime.split(':')[0] >= 12) ? 'PM' : 'AM'}`}
                    </option>
                  ))}
                </select>
              </div>
              {error && (
                <div style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
                  {error}
                </div>
              )}
              
              <div className='AP-button3' type="button" onClick={handleAddAppointment} style={{marginTop:"15px"}}>
                <div className='inner-AP-button'>
                  <div className='AP-text1'>SAVE</div>
                </div>
              </div>
            </>
          ) : (
            <p style={{color:"red"}}>No appointment types available. Please try for another date.</p>
          )}
        </form>
      </Modal>


    </div>
  );
};
