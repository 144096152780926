import { initializeApp } from "firebase/app";
import { getAuth, authSettings } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getFunctions, httpsCallable } from 'firebase/functions';

// // //Prod credentials 
const firebaseConfig = {
  apiKey: "AIzaSyA91QtzJQa0jVll09Fp8wQ5Ra-gVsXLPqY",
  authDomain: "snaiyu-7b192.firebaseapp.com",
  databaseURL: "https://snaiyu-7b192-default-rtdb.firebaseio.com",
  projectId: "snaiyu-7b192",
  storageBucket: "snaiyu-7b192.appspot.com",
  messagingSenderId: "284536061530",
  appId: "1:284536061530:web:d1751846d3dedd06c52263",
  measurementId: "G-KQCQJLEW3T"
};


 // Testing Credentials 
// const firebaseConfig = {
//   apiKey: "AIzaSyBwGk4HIFSScYVGFJkHfv3bXzlecXcI45Y",
//   authDomain: "testingwizio.firebaseapp.com",
//   projectId: "testingwizio",
//   storageBucket: "testingwizio.appspot.com",
//   messagingSenderId: "392286613900",
//   appId: "1:392286613900:web:c0ba2f855ee087e19666a9",
//   measurementId: "G-NDHE7EC9ST"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);



export const auth = getAuth(app); 
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics();
// if (auth && authSettings().appVerificationDisabledForTesting) {
//   console.log("App verification is disabled for testing.");
// }

let messaging;
try {
    messaging = getMessaging(app);
} catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
}
export const requestForToken = async () => {
  try {
    // Testing Wizio
    const token = await getToken(messaging, { vapidKey: "BLPjVrzwtxslOe5VspVELEW8WD86dIviCmxp7ISZUAVEpsfuZx5mmltnK80j0WVCX07SGBGoW2qtv3Y7_bD3RSM" });
    // const token = await getToken(messaging, { vapidKey: "BKW67ub5JUk4WfOdJqc2IfFfLF-2pzAAD7X7ueWYQ4VUSIM_-6MAWtgui_steMxfzn2uHd_jRV6PQMtDKEGnDHQ" });
    if (token) {
      console.log("FCM token:", token);
      // Send the token to your server or save it locally for future use
      return token;
    } else {
      console.log("No registration token available. Request permission to generate one.",token);
      return 0;
    }
  } catch (error) {
    console.error("An error occurred while retrieving token.", error);
    return 0;
  }
};

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // Customize your notification here or trigger a UI update
});


export default app;

