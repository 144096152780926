import React, { useState, useEffect } from 'react';
import { useUserAuth } from "../context/UserAuthContext";
import left from '../images/Left.png';
import { useNavigate, Link } from 'react-router-dom';
import myplanpic from '../images/myplanpic.png';
import Skeleton from 'react-loading-skeleton';
import spinner from '../images/loaderr.gif'; // Import spinner image
import download from '../images/Downloading Updates.png';

const MyPrograms = () => {
  const { getAllPrograms, getProgramCodes } = useUserAuth();
  const [programs, setPrograms] = useState([]);
  const [programsCodes, setProgramsCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [imageLoadingStates, setImageLoadingStates] = useState({}); // To track loading state for each image

  const navigate = useNavigate();
  
  const handleImageClick = () => {
    navigate('/profile');
  };

  const DownloadProgramCodes = async () => {
    try {
      setDownloadLoading(true);
      const response = await fetch('https://d14m49meqk42ly.cloudfront.net/dev/programCodesPDF', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
        },
        body: JSON.stringify(programsCodes),
      });
      const data = await response.json();
      console.log("DOwnload PDF response ",data);
      const pdfUrl = data.body.url;
      window.open(pdfUrl);
      setDownloadLoading(false);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      const storedPrograms = JSON.parse(localStorage.getItem('Program'));

      if (storedPrograms && storedPrograms.length > 0) {
        setPrograms(storedPrograms);
        console.log('Using programs data from localStorage.');
      }
      // console.log('Fetching programs data...');
      // const programsData = await getAllPrograms();
      // localStorage.setItem('Program', JSON.stringify(programsData));
      // setPrograms(programsData);
      setLoading(false);
      const data = JSON.parse(localStorage.getItem('codes'));
      console.log("data",data)
      setProgramsCodes(data);
      // const codes = await getProgramCodes();
      // if(codes.length>0){
      //   setProgramsCodes(codes);
      // }
    };

    fetchPrograms();
  }, []);

  // Function to find the corresponding program code
  const findProgramCode = (programId) => {
    const programCode = programsCodes.find(code => code.id === programId);
    return programCode ? programCode.code : 'NA'; // Default to 'N/A' if no code is found
  };

  const handleImageLoad = (index) => {
    setImageLoadingStates((prev) => ({ ...prev, [index]: false }));
  };


  return (
    <div className='profile-page' style={{ marginTop: '40px' }}>
      <div style={{ marginLeft: '5%', display: 'flex', justifyContent: 'space-between', marginRight: '5%' }}>
        <div>
          <img
            className='AP-back-image'
            src={left}
            alt='back'
            onClick={handleImageClick}
          />
          &nbsp; &nbsp;
          <span className='calender-text' style={{ fontSize: '24px', fontWeight: '700' }}>My Programs</span>
        </div>
        <img
          className='AP-back-image'
          src={download}
          alt='download'
          onClick={DownloadProgramCodes}
        />
      </div>
      <div className='myplan-banner' style={{ marginBottom: '3%' }}>
        {loading ? (
          <div style={{ width: '90%' }}>
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
          </div>
        ) : (
          <div className='program-list'>
            {programs.map((program, index) => {
              const exerciseName = program._document.data.value.mapValue.fields.Program_name.stringValue.toLowerCase();
              const TemplateID = program._document.data.value.mapValue.fields.Template_id.stringValue.toLowerCase();

              const getRandomThumbnail = (joint) => {
                const cycleIndex = index % 3 + 1;
                return `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${joint}Thumbnail${cycleIndex}.png?alt=media&token=c94ba79a-26ce-4efb-9f4f-70865845ef68`;
              };

              const jointImages = {
                knee: getRandomThumbnail('Knee'),
                hip: getRandomThumbnail('Hip'),
                shoulder: getRandomThumbnail('Shoulder'),
                neck: getRandomThumbnail('Neck'),
                back: getRandomThumbnail('Back'),
                ankle: getRandomThumbnail('Ankle'),
              };

              let imgSrc = Object.keys(jointImages).find((joint) => exerciseName.includes(joint)) || myplanpic;
              imgSrc = Object.keys(jointImages).find((joint) => TemplateID.includes(joint)) || myplanpic;

              if (imgSrc !== myplanpic) {
                imgSrc = jointImages[imgSrc];
              }

              const programId = program._document.key.path.segments[6];
              const programCode = findProgramCode(programId);

              return (
                <div className='program-item' key={index}>
                  <Link to={`/plan/${programId}/${program._document.data.value.mapValue.fields.Program_name.stringValue}/${programCode}`} style={{ textDecoration: 'none' }}>
                    <div className='myplan-body'>
                      {imageLoadingStates[index] !== false && (
                        <img
                          src={spinner}
                          alt='Loading...'
                          className='myplan-spinner'
                        />
                      )}
                      <img
                        src={imgSrc}
                        alt='My Plan'
                        className='myplan-image'
                        style={{
                          display: imageLoadingStates[index] === false ? 'block' : 'none',
                        }}
                        onLoad={() => handleImageLoad(index)}
                      />
                      <div className='myplan-text2new' style={{ fontSize: '18px' }}>
                        {program._document.data.value.mapValue.fields.Program_name.stringValue}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        {programCode !== 'NA' && (
                          <div style={{ color: "black", fontSize: "20px", textDecoration: "none", fontWeight: "800" }}>{programCode}</div>
                        )}
                        <div className='myplan-text3' style={{ width: "50px" }}>View Plan</div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
        <br /> <br /> <br /> <br /> <br />
        <Link type="submit" className='AP-button2' to='/joint'  > 
          <div className='inner-AP-button'>
            <div className='AP-text'>CREATE</div>
          </div>
        </Link>
      </div>
      {downloadLoading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPrograms;
