import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    getAuth,
    setPersistence,
    browserSessionPersistence, // Import session persistence option
} from "firebase/auth";
import { collection, 
    addDoc, 
    getDocs, 
    getDoc , 
    doc, 
    setDoc ,
    query,
    getFirestore ,
    Timestamp ,
    updateDoc ,
    serverTimestamp,
    where,
    deleteDoc,
    writeBatch
  } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { logEvent } from "firebase/analytics";
import { auth, firestore, storage, analytics, requestForToken } from "../firebase/index";
// import { encryptData, decryptData } from './encryptionUtils';
import { encryptData } from "./EncryptionUtils";
import { errors } from "pages/components/ErrorHandling";
import { assessment } from "pages/components/AssessmentData";
import moment, { duration } from 'moment';
import countries from '../utils/countries'
// import axios from 'axios';
import cors from 'cors';


import 'firebase/storage';  // <----e

const UserAuthContext = createContext();


export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState(null);
    const [result, setResult] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [ Events, setEvent ] = useState([]);
    const [ DocEvents, setDocEvent ] = useState([]);
    const [ID, setID] = useState();
    const [consultation, setConsultation] = useState(false);
    const [docsCalenderBool, setDocsCalenderBool] = useState(false);
    const [showDocCalender, setShowDocCalender] = useState(false);
    const [verified, setVerified] = useState(false);

    const isOnline = () => window.navigator.onLine;


  
    useEffect(() => {
      const userData = localStorage.getItem('userData');
      const id = localStorage.getItem('ID');
      // console.log("auth value ",auth.currentUser);
      // const currentuser = localStorage.setItem('currentuser', JSON.stringify(auth));
      // console.log("this is from local storage of userStorage in auth context ",currentuser);
      if (userData) {
        const parsedUser = JSON.parse(userData);
        console.log("the value of parsed user ",parsedUser)
        setUser(parsedUser);
        setID(JSON.parse(id));
        // setID('9821053033');
      } else if (!isOnline()) {
        // Show a pop-up or perform an action for no internet
        // Example: You can show a pop-up with a message about no internet connectivity.
        alert("No internet connectivity. Please check your internet connection.");
      }
    }, [auth]);
  

    function signUp(email, password) {
      return createUserWithEmailAndPassword(auth, email, password);
    }

    function logIn(email, password) {
      setLoggedIn(true);
      console.log("logging in")
      return signInWithEmailAndPassword(auth, email, password);
    }
    function logOut(){
      setLoggedIn(false);
      return signOut(auth);
    }
    // async function findDuplicateProgramNames() {
    //   const programCodesRef = collection(firestore, "ProgramCodes");
    //   const snapshot = await getDocs(programCodesRef);
    
    //   const programNameCounts = {};
    //   snapshot.forEach((doc) => {
    //     const data = doc.data();
    //     const programName = data.programName;
    //     if (programName) {
    //       programNameCounts[programName] = (programNameCounts[programName] || 0) + 1;
    //     }
    //   });
    
    //   // Identify duplicates
    //   const duplicates = Object.keys(programNameCounts).filter(
    //     (name) => programNameCounts[name] > 1
    //   );
    
    //   console.log("Duplicate Program Names:", duplicates);
    // }


    function loggingIn(number,password){
      // auth.settings.appVerificationDisabledForTesting = true;
      // firebase.Auth.settings.appVerificationDisabledForTesting = true;
      const email = number + "@wizphys.com"
      return signInWithEmailAndPassword(auth, email, password);

    }

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          // User is signed in, you can access the user data here.
          setUser(currentUser);
    
          // Store user data in localStorage
          localStorage.setItem('userData', JSON.stringify(currentUser));
          console.log("local data in useeffect ",localStorage.getItem('userData'));
        } else {
          // User is signed out or not authenticated.
          setUser(null);
    
          // Remove user data from localStorage
          localStorage.removeItem('userData');
          localStorage.removeItem('ID');
          localStorage.setItem('CalenderEvents',JSON.stringify([]));
          localStorage.setItem('DocCalenderEvents',JSON.stringify([]));
          localStorage.setItem('VideoCallHistoryData',JSON.stringify([]));
          localStorage.setItem('WeeklyUpcomingAppointmentsData',JSON.stringify([]));
          localStorage.setItem('ProgramRenewalData',JSON.stringify([]));
        }
      });
    
      // Cleanup the subscription when the component unmounts
      return () => unsubscribe();
    }, [auth]);
    

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await fetchPhysioData();
          console.log("This is firebase data",data.validityDate);

          const validityDate = new Date(data.validityDate.seconds * 1000);

          // Get current date and time
          const currentDate = new Date();

          // Check if validityDate is greater than currentDate
          if(data.AssignPrepaidProgram){
            if (validityDate > currentDate) {
              setVerified(data.Verified);
            } else {
              setVerified(false);
            }
          }else{
            setVerified(data.Verified);
          } 
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      if (ID) {
        fetchData();
      }
    }, [ID]);
  

    
    // this function is for Physio Profile Data
    const fetchPhysioData = async () => {
      const uid = ID;
      console.log("The ID of physio:", uid, ID);
  
      try {
          if (uid) {
              const collectionRef = doc(firestore, `Physio/${uid}`);
              const querySnapshot = await getDoc(collectionRef);
              const profiles = querySnapshot.data();
              console.log("This is the profiles:", JSON.stringify(profiles));
  
              // Ask for notification permission
              // const permission = await Notification.requestPermission();
              // if (permission === 'granted') {
              //     // Get the FCM token
              //     const token = await requestForToken();
              //     if (token) {
              //         // Update the FCM token in Firestore
              //         await updateDoc(
              //             collectionRef,
              //             { "FCM token": token },
              //             { merge: true }
              //         );
              //         console.log("FCM token updated in Firestore:", token);
              //     } else {
              //         console.log("No FCM token generated.");
              //     }
              // } else {
              //     console.log("Notification permission not granted.");
              // }
  
              return profiles;
          } else {
              return [];
          }
      } catch (error) {
          console.error("Error getting profile documents:", error);
          return [];
      }
  };
  

    function setUpRecaptcha(number, countryCode){
      // auth.settings.appVerificationDisabledForTesting = true;
      // firebase.Auth.settings.appVerificationDisabledForTesting = true;
      console.log("checkpoint")
      console.log("number ",number);
      number = countryCode+number
      console.log(" Edited number ",typeof(number),number);
      // encryptData(number);
      const recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container');
      recaptchaVerifier.render();
      return signInWithPhoneNumber(auth, number, recaptchaVerifier);
    }

    // this function is for fetching details of all the Patients for a specific Physio
    // const collectionRef = collection(firestore, `/Physio/tqCMdfZCjJA7VZdiJs2oLw==/patients`); 
    const getPhysioPatients = async () => {
        const user = auth.currentUser;
      
        if (!user) {
          console.log("User not authenticated");
          return [];
        }
      
        const uid = ID;
        // const uid = "tqCMdfZCjJA7VZdiJs2oLw==";
        try {
          if (uid) {
            // console.log("the physio uid ",uid)
            // const collectionRef = collection(firestore, `/Physio/tqCMdfZCjJA7VZdiJs2oLw==/patients`);   // this need to be changed 
            const collectionRef = collection(firestore, `/Physio/${uid}/patients`);
            const querySnapshot = await getDocs(collectionRef);
            const profiles = querySnapshot.docs;
            // console.log("profile", profiles);
      
            const patientsData = await Promise.all(profiles.map(async (profile) => {
              const data = profile._document.data.value.mapValue.fields;
              const patientData = {
                PatientNameFP: data.PatientNameFP?.stringValue || 'NA',
                Patient_idFP: data.Patient_idFP?.stringValue || 'NA',
                PatientTreatmentFP: data.PatientTreatmentFP?.stringValue || 'NA',
                PatientMobileFP: data.PatientMobileFP?.stringValue|| 'NA',
                PatientTotalScore: '0',
                ConsultationRequested: data.ConsultationRequested?.booleanValue|| false,
                AllData: data
              };
      
              const patientUid = profile.id;
            //   console.log("patient ID", profile.id);
              const reportDocRef = doc(firestore, `${patientUid}/reports`);
            //   console.log("reportDocRef is ", reportDocRef);
      
              try {
                const reportDocSnapshot = await getDoc(reportDocRef);
      
                if (reportDocSnapshot.exists()) {
                  const reportData = reportDocSnapshot.data();
                  const programUid = reportData.programUid;
                  const scoreDocRef = doc(firestore, `${patientUid}/reports/${programUid}/ProgramScores`);
                  const scoreDocSnapshot = await getDoc(scoreDocRef);
                  
                  if (scoreDocSnapshot.exists()) {
                    const scoreData = scoreDocSnapshot.data().tqCMdfZCjJA7VZdiJs2oLw;
                    patientData.PatientTotalScore = scoreData?.integerValue + '%' || '0';
                  }
                }
              } catch (error) {
                console.error("Error getting report or program scores:", error);
              }
      
              return patientData;
            }));
            
            // console.log(patientsData);
            localStorage.setItem('Data',JSON.stringify(patientsData));
            return patientsData;
          } else {
            return [];
          }
        } catch (error) {
          console.error("Error getting profile documents:", error);
          return [];
        }
      };
      
      
      
      
      
    // this function is for fetching Profile Data of a single Patient 
    const getPatientsDetails = async (uid) => {        
            // console.log("checking patient uid ", uid)
            try {
                const collectionRef = doc(firestore, `${uid}/ProfileData`);
                const docSnapshot = await getDoc(collectionRef);     
                // console.log("docsnapshot of the profile ",docSnapshot);       
                if (docSnapshot._document?.data.value.mapValue.fields) {
                  return docSnapshot._document.data.value.mapValue.fields;
              } else {
                  return {
                      hasPhysio: { stringValue: "NA" },
                      FreeTrialCurrentDay: { stringValue: "NA" },
                      BandColour: { stringValue: "NA" },
                      CurrentProgramName: { stringValue: "NA" },
                      IsEligible: { stringValue: "NA" },
                      FreeTrialStartDate: { stringValue: "NA" },
                      isExistingUser: { stringValue: "NA" },
                      isFirstTime: { stringValue: "NA" },
                      patient_Gender: { stringValue: "NA" },
                      DocumentID: { stringValue: "NA" },
                      showRecomendedPlan: { stringValue: "NA" },
                      jointSelected: { stringValue: "NA" },
                      patient_Number: { stringValue: "NA" },
                      patient_DOB: { stringValue: "NA" },
                      CurrentProgramID: { stringValue: "NA" },
                      currentDay: { stringValue: "NA" },
                      // patient_FreeTrialUser: { stringValue: "NA" },
                      channel_ID: { stringValue: "NA" },
                      selectedlanguage: { stringValue: "NA" },
                      patient_email: { stringValue: "NA" },
                      sideSelected: { stringValue: "NA" },
                      patient_RefCode: { stringValue: "NA" },
                      ispaymentdone: { stringValue: "NA" },
                      patient_name: { stringValue: "NA" },
                  };
              }
            
            } catch (error) {
                console.error("Error getting patient profile documents:", error);
                return {hasPhysio: { stringValue: "NA" },
                FreeTrialCurrentDay: { stringValue: "NA" },
                BandColour: { stringValue: "NA" },
                CurrentProgramName: { stringValue: "NA" },
                IsEligible: { stringValue: "NA" },
                FreeTrialStartDate: { stringValue: "NA" },
                isExistingUser: { stringValue: "NA" },
                isFirstTime: { stringValue: "NA" },
                patient_Gender: { stringValue: "NA" },
                DocumentID: { stringValue: "NA" },
                showRecomendedPlan: { stringValue: "NA" },
                jointSelected: { stringValue: "NA" },
                patient_Number: { stringValue: "NA" },
                patient_DOB: { stringValue: "NA" },
                CurrentProgramID: { stringValue: "NA" },
                currentDay: { stringValue: "NA" },
                // patient_FreeTrialUser: { stringValue: "NA" },
                channel_ID: { stringValue: "NA" },
                selectedlanguage: { stringValue: "NA" },
                patient_email: { stringValue: "NA" },
                sideSelected: { stringValue: "NA" },
                patient_RefCode: { stringValue: "NA" },
                ispaymentdone: { stringValue: "NA" },
                patient_name: { stringValue: "NA" }};
            }
        };

      
       

    // this function will fetch active programs of the given patients 
    const patientActiveProgram = async (uid) => {
        // console.log("checking patient uid ", uid);
        
        try {
          if (uid) {
            const collectionRef = collection(firestore, `${uid}/programs/activeprograms`);
            const querySnapshot = await getDocs(collectionRef);
            const profiles = querySnapshot.docs;
      
            // console.log("Program Details ", profiles);
            
            const extractedData = profiles.map((profile) => {
              // console.log("inside loop",profile)
              const ID = profile._key.path.segments[8];
              
              // console.log("ID",ID)
              var duration, color, enddate, sideAssigned, renewalRecommended, Payment_done, startdate, currentDay;
              var active = "False";
            
              if(ID!=='FreeTrialProgram'){
                const programName = profile._document.data.value.mapValue.fields.Program_name.stringValue;
                try{
                  duration = profile._document.data.value.mapValue.fields.Duration.stringValue;
                }catch{
                  duration = 0;
                }
                try{
                  color = profile._document.data.value.mapValue.fields.TherabandColor_Assigned?.arrayValue.values[0].stringValue;
                }catch{
                  color = null;
                }
                try {
                  enddate = profile._document.data.value.mapValue.fields.End_date?.stringValue || null;
                  startdate = profile._document.data.value.mapValue.fields.Start_date?.stringValue || null;
                } catch {
                  enddate = '';
                  startdate = '';
                }
                
                try{
                  renewalRecommended = profile._document.data.value.mapValue.fields.renewalRecommended?.stringValue;
                }catch{
                  renewalRecommended = null;
                }

                try{
                  Payment_done = profile._document.data.value.mapValue.fields.Payment_done?.stringValue;
                }catch{
                  Payment_done = null;
                }

                try{
                  sideAssigned = profile._document.data.value.mapValue.fields.SideAssigned?.stringValue || profile._document.data.value.mapValue.fields.Hand_Assigned?.stringValue;
                }catch{
                  sideAssigned = null;
                }

                if (startdate && enddate) {
                  const today = new Date();
                  const dateString = enddate;
                  const dateParts = dateString.split(" ");
                  const datePart = dateParts[0].split("/");
                  const day = parseInt(datePart[0], 10);
                  const month = parseInt(datePart[1], 10) - 1; 
                  const year = parseInt(datePart[2], 10);
                  const endDate = new Date(year, month, day);
                  
                  const dateString1 = startdate;
                  const dateParts1 = dateString1.split(" ");
                  const datePart1 = dateParts1[0].split("/");
                  const day1 = parseInt(datePart1[0], 10);
                  const month1 = parseInt(datePart1[1], 10) - 1; 
                  const year1 = parseInt(datePart1[2], 10);
                  const startDate = new Date(year1, month1, day1);
                
                  // console.log("current day calculation 1:", today.toISOString(), startDate.toISOString(), endDate.toISOString());
                
                  if (endDate >= today) {
                    const timeDifference =parseInt((today - startDate) / (1000 * 60 * 60 * 24));
                    // console.log("the current day ----------------",timeDifference,endDate,startDate,today);
                    currentDay =  timeDifference;
                    active = "True";
                  } else {
                    // If enddate is less than today, set currentDay to duration
                    currentDay = duration;
                    active = "False";
                  }
                } else {
                  // If startdate or enddate is null, set currentDay to 0
                  currentDay = 0;
                }
               
                return {
                    programName,
                    ID,
                    duration,
                    color,
                    enddate,
                    sideAssigned,
                    renewalRecommended,
                    Payment_done,
                    profile,
                    currentDay,
                    startdate,
                    active,
                };
              }              
            }).filter((entry) => entry !== undefined);
      
            // console.log("Extracted Data:-------------------------", extractedData);
      
            const sortedData = extractedData.sort((a, b) => {
              const getDate = (dateString) => {
                if (dateString) {
                  const dateParts = dateString.split(" ");
                  const datePart = dateParts[0].split("/");
                  const day = parseInt(datePart[0], 10);
                  const month = parseInt(datePart[1], 10) - 1;
                  const year = parseInt(datePart[2], 10);
                  return new Date(year, month, day);
                }
                // Return a default date (e.g., current date) if dateString is null
                return new Date(null);
              };
            
              const dateA = getDate(a.enddate);
              const dateB = getDate(b.enddate);
              // console.log("this is where we are sorting based on enddate ",dateA,dateB);
              return dateB - dateA;
            });
            
            // console.log("Sorted Data by End Date:-------------------------", sortedData);
            
            return sortedData;
            // return extractedData;
          } else {
            return [];
          }
        } catch (error) {
          console.error("Error getting program documents:", error);
          return [];
        }
      };

      
      // Fetch exercise data for a specific day 
      const getExerciseData = async (patientId, programId, selectedDay) => {
        console.log("inside excerdata function ");
        try {
          const exerciseDocPath = `${patientId}/reports/${programId}/Day_${selectedDay}/exercises`;
          const exerciseCollectionRef = collection(firestore, exerciseDocPath);
          const querySnapshot = await getDocs(exerciseCollectionRef);
          console.log("lets see whats in querySnapShot ",querySnapshot);
      
          const exerciseData = querySnapshot._snapshot.docChanges.map((doc) => {
            const exerciseRepCountData = doc.doc.data.value.mapValue.fields.ExerciseRepCount.mapValue.fields;
            console.log("printing excercise Rep Count Data ",exerciseRepCountData);
            const errorCounts = {};
            const name = doc.doc.data.value.mapValue.fields.ExerciseName.stringValue;
            const cleanedName = name.replace(/left|right/gi, '').trim();
            console.log("this is the name ",cleanedName);
            Object.values(exerciseRepCountData).forEach((entry) => {
              const errorsString = entry.stringValue;
              const errorEntries = errorsString.split(', ');
      
              errorEntries.forEach((errorEntry) => {
                const [errorName, errorValue] = errorEntry.split(' : ');
                if(errorValue!==undefined){
                  const cleanedErrorName = errorName.trim().replace('{', '').replace('}', '');
                  const cleanedErrorValue = parseInt(errorValue.trim());
        
                  if (cleanedErrorValue !== 0) {
                    const errorConfig = errors.find((config) => config.error === cleanedErrorName);
                    if (errorConfig) {
                      if (!errorCounts[cleanedErrorName]) {
                        let badImagePath;
                        let goodImagePath;
                        console.log("this si the error", cleanedErrorName);
                        if (cleanedErrorName === 'ShruggingError' || cleanedErrorName === 'NeckTiltError') {
                            // For ShruggingError and NeckTiltError, use ExerciseName_ErrorName_Normal.png pattern
                            badImagePath = `${cleanedName}_${cleanedErrorName}`;
                            goodImagePath = `${cleanedName}_${cleanedErrorName}_Normal`;
                        } else {
                            // For other errors, use ExerciseName_Normal.png pattern
                            badImagePath = `${cleanedName}_${cleanedErrorName}`;
                            goodImagePath = `${cleanedName}_Normal`;
                        }
                        console.log("bad image and gud image ",badImagePath,goodImagePath);
                        // Assigning image paths based on error type
                        errorCounts[cleanedErrorName] = {
                          name: cleanedErrorName,
                          count: 0,
                          badtext: errorConfig.badtext,
                          goodtext: errorConfig.goodtext,
                          badimage: badImagePath,
                          goodimage: goodImagePath,
                        };
                      }
                      errorCounts[cleanedErrorName].count += cleanedErrorValue;
                    }
                  }
                }
              });
            });
      
            return {
              ExerciseName: doc.doc.data.value.mapValue.fields.ExerciseName.stringValue,
              ExerciseRepCount: doc.doc.data.value.mapValue.fields.ExerciseRepCount.mapValue.fields,
              ExerciseScore: doc.doc.data.value.mapValue.fields.ExerciseScore.stringValue,
              HoldTime: doc.doc.data.value.mapValue.fields.HoldTime,
              ExerciseDetailsPushDateTime: doc.doc.data.value.mapValue.fields.ExerciseDetailsPushDateTime.stringValue,
              ErrorCounts: Object.values(errorCounts),
              RepCount: Object.keys(doc.doc.data.value.mapValue.fields.ExerciseRepCount.mapValue.fields).length,
            };
          });
      
          console.log("final data to be returned ", exerciseData);
          return exerciseData;
        } catch (error) {
          console.error("Error getting exercise data:", error);
          return null;
        }
      };


      const getAssessmentData = async (patientId, programID, duration) => {
        console.log("inside assessment Data function ", duration);
        try {
            const aggregatedData = {};
            const numberOfAssessments = Math.ceil(duration / 15);
    
            // Initialize aggregatedData with '-' for all keys
            const keysToInitialize = []; // Track all unique keys for initialization
            for (let i = 0; i < numberOfAssessments; i++) {
                const assessmentKey = `${patientId}/reports/${programID}/assessment_${i}`;
                const assessment = await getDoc(doc(firestore, assessmentKey));
    
                if (!assessment.exists) {
                    // console.log(`Assessment ${i} does not exist`);
                    // Track all keys to initialize with '-'
                    Object.keys(aggregatedData).forEach(key => {
                        keysToInitialize.push(key);
                    });
                    continue; // Skip to the next assessment
                }
    
                const assessmentData = assessment.data() || {}; // Handle potential null or undefined
                // console.log("Data for assessment ", i);
                // console.log("Assessment Data ", assessmentData);
    
                Object.keys(assessmentData).forEach(key => {
                    if (!aggregatedData[key]) {
                        aggregatedData[key] = Array(numberOfAssessments).fill('-'); // Initialize array with '-' for each key
                    }
                    aggregatedData[key][i] = assessmentData[key];
                });
            }
    
            // Initialize all keys that were tracked to initialize with '-'
            keysToInitialize.forEach(key => {
                if (!aggregatedData[key]) {
                    aggregatedData[key] = Array(numberOfAssessments).fill('-');
                }
            });
    
            console.log("Aggregated Data:", aggregatedData);
    
            const finalData = {};
            Object.keys(aggregatedData).forEach(key => {
                finalData[key] = aggregatedData[key];
            });
    
            const programdata = await patientActiveProgram(patientId);
            const side = programdata[0].sideAssigned.toLowerCase();
            console.log("this is side --------------------------------------------------------------", side);
            const filteredData = {};
            Object.keys(finalData).forEach(key => {
                if (side === "both" || key.toLowerCase().includes(side)) {
                    filteredData[key] = finalData[key];
                }
            });
    
            console.log("Final Assessment Data ", filteredData);
            return filteredData;
    
        } catch (error) {
            console.error("Error getting assessment data:", error);
            return null;
        }
      };    
    
      
      
    
      // Function to fetch overview data
      async function getOverviewData(patientId, programId) {
        // console.log("inside getOverview Data function in UserAuthContext ",patientId,programId)
        try {
            const scoreDocRef = doc(firestore, `${patientId}/reports/${programId}/ProgramScores`);
            const scoreDocSnapshot = await getDoc(scoreDocRef);
            // console.log("this is where we are getting actuall score 1",patientId,scoreDocSnapshot.data())
            
            if (scoreDocSnapshot.exists()) {
                const scoreData = scoreDocSnapshot.data();
                // console.log("this is where we are getting actuall score 2",patientId,scoreData);
                return scoreData;
            } else {
                return {
                  GrandTotalScore: 0,
                  AdherenceScore: 0,
                  StreakScore: 0,
                };
            }
        } catch (error) {
            console.error("Error getting overview data:", error);
            return null;
        }
      }

      // to fetch Histogram data 
      async function getHistogramData(patientID, programID, duration, current_day) {
        console.log("inside get Histogram Data function in UserAuthContext ",patientID,programID,duration, current_day)
        const histogramData = [];
        try{
          const ref = doc(firestore, `${patientID}/reports/${programID}/ProgramScores`);
          console.log("this is the reference ",ref);
          const snapshot = await getDoc(ref);
          const data = snapshot.data();
          console.log("this is the program Scores data ",data);
          if (data.DayScoreList) {
            const array = data.DayScoreList;
      
            // Create an array for the entire specified duration
            const fullData = Array.from({ length: duration }, (_, index) => ({
              day: index + 1,
              score: Math.min(array[index] * 100, 100) || 0
            }));
      
            console.log("this is the full data ", fullData);
            return fullData;
          }
      
        }catch(e){
          console.log("there is no data in direct way ",e);
        }
        try {
          for (let day = 1; day <= duration; day++) {
            if (day <= current_day) {
              const scoreDocRef = doc(firestore, `${patientID}/reports/${programID}/Day_${day}`);
              const scoreDocSnapshot = await getDoc(scoreDocRef);
              const scoreData = scoreDocSnapshot.data();
              const score = scoreData && scoreData.ExerciseScore ? scoreData.ExerciseScore : 0;
      
              console.log("data of histogram for day  ", day, score);
              histogramData.push({ day, score });
            } else {
              // For days beyond current_day, set the score to 0
              histogramData.push({ day, score: 0 });
            }
          }
        } catch (error) {
          console.error("Error getting histogram data:", error);
        }
      
        return histogramData;
      }

    // this function is for fetching program details 
    const getAllPrograms = async () => {
      const physioId = ID;
        try {
            const collectionRef = collection(firestore, "/programsForHK"); // this is for prod 
            // const collectionRef = collection(firestore, "/programs");
            const querySnapshot = await getDocs(collectionRef);
            let programs = querySnapshot.docs         
            const profileRef = doc(firestore, `Physio/${physioId}`);
            const profileSnapshot = await getDoc(profileRef);       
            const profileData = profileSnapshot.data();
            console.log("programs: ProfileData",profileData);
            const customProgramFlag = profileData.customProgram || "False";
            if(customProgramFlag === "True"){
              const collectionRef = collection(firestore, "/CustomPrograms"); // this is for prod 
              const querySnapshot = await getDocs(collectionRef);
              const customPrograms = querySnapshot.docs    
              console.log("programs: Custom Program ",customPrograms);
              const filteredData = customPrograms.filter(item => {
                const segments = item._key.path.segments;
                return (
                  segments.length > 6 &&
                  profileData.customProgramID.includes(segments[6])
                );
              });
              console.log("programs: filtered programs",filteredData);
              programs = [...filteredData, ...programs,]
              console.log("first")     
            }
            console.log("programs",programs)
            localStorage.setItem('Program', JSON.stringify(programs)); 
            return programs;
        } catch (error) {
            console.error("Error getting programs documents:", error);
            return 10;
        }
    };

    const getProgramCodes = async () => {
      const physio_ID = ID;  // Make sure `ID` is set correctly
      try {
        const collectionRef = collection(firestore, "/ProgramCodes");
        const querySnapshot = await getDocs(collectionRef);
        let programs = querySnapshot.docs;
    
        let programsArray = [];
    
        programs.forEach(doc => {
          let data = doc.data();
    
          try {
            // console.log("value of programCode:", data.programCode);
            // console.log("value of templateID:", data.templateID);
            // console.log("value of physioID from Firestore:", data.physioID);
            // console.log("value of physio_ID being compared:", physio_ID);
    
            // Only proceed if all required fields are defined
            if (data.programCode && data.templateID && data.programName && data.physioID) {
              if (String(data.physioID) === String(physio_ID)) {
                console.log("PASSED ",data.programCode);
                programsArray.push({
                  code: data.programCode,
                  id: data.templateID,
                  name: data.programName,
                });
              }
            }
          } catch (e) {
            console.log("Error processing program data for doc:", doc.id, e);
          }
        });
    
        console.log("This is codes ", programsArray);
        localStorage.setItem('codes', JSON.stringify(programsArray));
      
        return programsArray;
      } catch (e) {
        console.log("Error while fetching programs", e);
      }
    };
    
    
    
    
    async function getPlan(programID) {
      try {
          const docRef = doc(firestore, `/programsForHK/${programID}/exercises/day1`);
          // const docRef = doc(firestore, `/programs/${programID}/exercises/day1`);
          const docSnapshot = await getDoc(docRef);
          
          if (docSnapshot.exists()) {
              const planData = docSnapshot.data();
              return planData;
          } else {
              console.log("Document does not exist");
              const docRef = doc(firestore, `/CustomPrograms/${programID}/exercises/day1`);
              // const docRef = doc(firestore, `/programs/${programID}/exercises/day1`);
              const docSnapshot = await getDoc(docRef);
              
              if (docSnapshot.exists()) {
                  const planData = docSnapshot.data();
                  return planData;
              } else {
                  console.log("Document does not exist");
                  return null;
              }
          }
      } catch (error) {
          console.error("Error getting plan data:", error);
          return null;
      }
  }



    // Encrytption and Decription 

    function EncryptData11(number) {
        console.log("encrypting")
        const EncryptedText = encryptData(number);
        console.log(">E> " + EncryptedText);
        return EncryptedText;
      }
    
      // function DecryptData11(encryptData) {
      //   const DecryptedText = decryptData(encryptData);
      //   console.log(">D> " + DecryptedText);
      //   return DecryptedText;
      // }


    // fetching all the patient data 
    // adding patient data in Physio Collection 
    async function addPatientData(patientData,prepaidProgram,startDate) {
      console.log("In console log ",patientData,prepaidProgram,startDate);
      const maxRetries = 1;
      let retryCount = 0;
      let updateCountFlag = false;
      while (retryCount<maxRetries) {
        console.log("attempt no----------------------------------",retryCount);
        try {
            const customProgramFlag = patientData.customFlag;
            // const patientsCollection = doc(firestore, "ExistingPatients/OldPatientList");
            // const list1 = await getDoc(patientsCollection);
            // console.log("the list value -----------------", list1.data());
            // const listData = list1.data();
            const patientMobile = patientData["PatientMobileFP"];
            const patientMobile2 = patientData["CountryCode"]+patientData["PatientMobileFP"];

            var id = patientData['PatientID'];
            

            
            if(patientData.PatientID === 'createID'){
              console.log("inside the IF")
              const newUserDoc = doc(firestore, `/newuserids/user_id`);
              const newUserData = await getDoc(newUserDoc);
              if (newUserData.exists()) {
                const Data = newUserData.data();
                console.log("this is Data", Data);
        
                // Increment the uid_count by 1
                const updatedUidCount = Data.uid_count + 1;
                
                // Generate a new ID with six zeros in front of the updated uid_count
                const newId = String(updatedUidCount); // Adjusted to 8 digits to include the prefix and the count
                
                // Update the uid_count in the Firestore document
                Data.uid_count = updatedUidCount;

                // Add a new key-value pair to the uid object
                Data.uid[patientMobile2] = newId;

                id = newId;
                
                // Update the Firestore document with the new data
                await setDoc(newUserDoc, Data);
                
                console.log("Updated id", id);
              }
            }
            console.log("the final ID ", id);
            const Data = {
                ...patientData,
                PatientAddedDateFP: new Date().toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                }).replace(',', ''),
                PatientEmailFP: null,
                PatientGenderFP: null,
                PatientTotalScore: null,
                PatientMobileFP: patientData['PatientMobileFP'],
                Patient_idFP: id,
            };
            console.log("data to be added in patient final data ", Data);
            
       

                const physioId = ID; // Get the physio ID
                // const physioId = "Kj0lEf5DevcAQQE9ZughmA==";
                console.log("checkpoint 1")
          
              const assignedProgramCountPath = `Physio/${ID}`;
              const assignedProgramCountRef = doc(firestore, assignedProgramCountPath);

              // Get the current value of AssignedProgramCount
              const assignedProgramCountSnapshot = await getDoc(assignedProgramCountRef);
              console.log("this is checkpoint 1 ---------------",assignedProgramCountSnapshot.data());
              const currentCount = parseInt(assignedProgramCountSnapshot.data().AssignedProgramCount);
                

              // Increment the count by 1
              if(updateCountFlag===false){
                const newCount = (currentCount + 1).toString();
                console.log("this is the new count checkpoint 2 -------------------",newCount);
                // Update AssignedProgramCount with the new count
                await updateDoc(assignedProgramCountRef, {
                  AssignedProgramCount: newCount,
                });
                console.log("AssignedProgramCount updated successfully");
                updateCountFlag = true;
              }else{
                const newCount = (currentCount).toString();
              }
              


                // 1. this is for id/program/activeprograms fields 
              let sourcePath;
              if(customProgramFlag){
                sourcePath = `CustomPrograms/${patientData['FBTemplateid']}`;    // path needs to be changed here 

              }else{
                sourcePath = `programsForHK/${patientData['FBTemplateid']}`;    // path needs to be changed here 

              }
              // const sourcePath = `programs/${Data['FBTemplateid']}`;
              const amtPath = '/pricing/B2B_PricingChart';
              const amtRef = doc(firestore, amtPath);
              const amtSnapshot = await getDoc(amtRef);
              console.log("The value of amt SnapShot:",amtSnapshot._document.data.value.mapValue.fields[30].stringValue);
              let amount = amtSnapshot._document.data.value.mapValue.fields[30].stringValue;

              const destinationPath = `${Data['Patient_idFP']}/programs/activeprograms/${Data['Template_id']}`;
              console.log("this is breaking -----------------------",Data['Patient_idFP']);
              console.log("this might be breaking -----------------",sourcePath,destinationPath);
              const sourceCollectionRef = doc(firestore, sourcePath);
              const querySnapshot = await getDoc(sourceCollectionRef);
              console.log("this is checkpoint ",querySnapshot);
              let Payment_done = "False";
              let Start_date = "";
              let End_date = "";
        
              if(prepaidProgram){
                Payment_done = "True";
                const Startdate = new Date(startDate);
                const day = String(Startdate.getDate()).padStart(2, '0');
                const month = String(Startdate.getMonth() + 1).padStart(2, '0');
                const year = Startdate.getFullYear();
                Start_date = `${day}/${month}/${year} 00:00:00`;

                // Add the duration (in days) to the start date
                const Enddate = new Date(Startdate); // Create a copy of the start date
                Enddate.setDate(Startdate.getDate() + Number(patientData.duration)); // Adds days properly

                console.log("The value of END DATE IS ", Startdate, Enddate, Start_date, patientData.duration, typeof(patientData.duration));

                // Format the end date
                const day1 = String(Enddate.getDate()).padStart(2, '0');
                const month1 = String(Enddate.getMonth() + 1).padStart(2, '0');
                const year1 = Enddate.getFullYear();
                End_date = `${day1}/${month1}/${year1} 00:00:00`;

                console.log("The value of start date and end date ", Start_date, Enddate, End_date);
              }
              if(customProgramFlag){
                try {
                  console.log("here we are checking amt value", querySnapshot._document.data.value.mapValue.fields.customPricing.mapValue.fields, physioId);
                  const fields = querySnapshot?._document?.data?.value?.mapValue?.fields?.customPricing?.mapValue?.fields || {};
                  amount = fields[physioId]?.stringValue || amount;
                } catch (error) {
                  console.error('Error accessing customPricing fields:', error);
                } 
              }

              let joint;

              if(patientData["updateProfile"]===2){
                
                console.log("the value of id", Data['FBTemplateid'] )
                if (Data['FBTemplateid'].toLowerCase().includes('shoulder')) {
                  joint = 'Shoulder';
                } else if (Data['FBTemplateid'].toLowerCase().includes('knee')) {
                  joint = 'Knee';
                } else if (Data['FBTemplateid'].toLowerCase().includes('hip')) {
                  joint = 'Hip';
                } else if (Data['FBTemplateid'].toLowerCase().includes('ankle')) {
                  joint = 'Ankle';
                } else if (Data['FBTemplateid'].toLowerCase().includes('neck')) {
                  joint = 'Neck';
                } else if (Data['FBTemplateid'].toLowerCase().includes('back')) {
                  joint = 'Back';
                } else {
                  joint = 'Joint'; // Default value or handle other cases as needed
                }

                console.log("the joint selected is", joint );
                const profilefields = {
                  BandColour: [Data['PatientColorFP']],
                  CurrentProgramName: Data['PatientAssignedProgramNameFP'],
                  DocumentID: Data['Template_id'],
                  ispaymentdone: Payment_done === "True",
                  jointSelected: joint,
                  // patient_FreeTrialUser: "True",
                  sideSelected: Data['PatientSideFP'],
                  hasPhysio: true,
                  IsEligible: true
                }

                const profileDataPath = `${Data['Patient_idFP']}/ProfileData`;
                const profileDataRef = doc(firestore, profileDataPath);
                const profileDoc = await getDoc(profileDataRef);

                if (profileDoc.exists()) {
                  await updateDoc(profileDataRef, profilefields);
                  console.log("ProfileData updated successfully");
                } else {
                  console.log("ProfileData does not exist, no update made");
                }
                // await updateDoc(profileDataRef, profilefields);
              }else{
                console.log("the value of id", Data['FBTemplateid'] )
                if (Data['FBTemplateid'].toLowerCase().includes('shoulder')) {
                  joint = 'Shoulder';
                } else if (Data['FBTemplateid'].toLowerCase().includes('knee')) {
                  joint = 'Knee';
                } else if (Data['FBTemplateid'].toLowerCase().includes('hip')) {
                  joint = 'Hip';
                } else if (Data['FBTemplateid'].toLowerCase().includes('ankle')) {
                  joint = 'Ankle';
                } else if (Data['FBTemplateid'].toLowerCase().includes('neck')) {
                  joint = 'Neck';
                } else if (Data['FBTemplateid'].toLowerCase().includes('back')) {
                  joint = 'Back';
                } else {
                  joint = 'Joint'; // Default value or handle other cases as needed
                }

                console.log("the joint selected is", joint );
                const profilefields = {
                  BandColour: [Data['PatientColorFP']],
                  CurrentProgramName: Data['PatientAssignedProgramNameFP'],
                  DocumentID: Data['Template_id'],
                  ispaymentdone: Payment_done === "True",
                  jointSelected: joint,
                  // patient_FreeTrialUser: "True",
                  sideSelected: Data['PatientSideFP'],
                  hasPhysio: true,
                  IsEligible: true
                }

                const profileDataPath = `${Data['Patient_idFP']}/ProfileData`;
                const profileDataRef = doc(firestore, profileDataPath);

                const profileDoc = await getDoc(profileDataRef);
  
                if (profileDoc.exists()) {
                  await updateDoc(profileDataRef, profilefields);
                  console.log("ProfileData updated successfully");
                } else {
                  console.log("ProfileData does not exist, no update made");
                }
              }
              const currentDate = new Date();
              const day = String(currentDate.getDate()).padStart(2, '0');
              const month = String(currentDate.getMonth() + 1).padStart(2, '0');
              const year = currentDate.getFullYear();
              const hours = String(currentDate.getHours()).padStart(2, '0');
              const minutes = String(currentDate.getMinutes()).padStart(2, '0');
              const seconds = String(currentDate.getSeconds()).padStart(2, '0');

              const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
              console.log(formattedDate);
              console.log("this is country code value ",patientData['CountryName']);
              console.log("The value of start date and end date ", Start_date, End_date);
              
              const fields = {
                Amount: amount,
                Assignment_date: formattedDate,
                Assignment_id: "",
                Document_id: Data['Template_id'],  
                Duration: String(patientData.duration),
                End_date: End_date,
                Hand_Assigned: Data['PatientSideFP'],
                Order_id: "",
                Patient_id: Data['Patient_idFP'],
                Payment_date: null,
                Payment_done: Payment_done,
                Physio_id: String(physioId),
                Program_id: Data['Template_id'],
                Program_name: Data['PatientAssignedProgramNameFP'],
                Start_date: Start_date,
                Template_id: Data['FBTemplateid'],
                TherabandColor_Assigned: [Data['PatientColorFP']],
                renewalRecommended: "",
                prepaidProgram: prepaidProgram,
                CustomProgramPatient: customProgramFlag,
                Joint: joint
              }
              const collectionRef4 = doc(firestore, destinationPath);
              console.log("Active Program Feilds will be added now 1" ,fields);

              await setDoc(collectionRef4, fields);
              console.log("Active Program Feilds Added Success Fully 2",fields);

              // 2. Profile Data Adding for new user in patient_list collection 

              const path = `patient_list/${Data['Patient_idFP']}`;
              const collectionRefe = doc(firestore, path);
              await setDoc(collectionRefe, Data);
              console.log("Patient data added successfully");


              // 3. now adding assessment and excersice to patient collection 

              let planid = patientData['FBTemplateid'];
              // path needs to be changed here  here we need to figure out the way to fix both left and right arena only for custom 
              
              let assessment_path, excercise_path;
              
              if(!customProgramFlag){
                excercise_path = `programsForHK/${planid}/exercises/day1`;
                assessment_path = `programsForHK/${planid}/assessment`;
              }else{
                excercise_path = `CustomPrograms/${planid}/exercises/day1`;
                assessment_path = `CustomPrograms/${planid}/assessment`;
              }

              // if (customProgramFlag) {
              //     planid = patientData['FBTemplateid'];
              // } else {
              //     if (patientData['FBTemplateid'] === "b2bHipandKnee0001") {
              //         planid = "B2bHip_001";
              //     } else if (patientData['FBTemplateid'] === "b2bHipandKnee0002") {
              //         planid = "B2bKnee_001";
              //     } else if (patientData['FBTemplateid'] === "b2bshoulder0001") {
              //         if (patientData['PatientSideFP'] === "Left") {
              //             planid = "B2bShoulderLeft_001";
              //         } else if (patientData['PatientSideFP'] === "Right") {
              //             planid = "B2bShoulderRight_001";
              //         } else {
              //             planid = "B2bShoulderBoth_001";
              //         }
              //     } else if (patientData['FBTemplateid'] === "b2bshoulder0002") {
              //         if (patientData['PatientSideFP'] === "Left") {
              //             planid = "B2bShoulderLeft_002";
              //         } else if (patientData['PatientSideFP'] === "Right") {
              //             planid = "B2bShoulderRight_002";
              //         } else {
              //             planid = "B2bShoulderBoth_002";
              //         }
              //     } else {
              //         planid = patientData['FBTemplateid'];
              //     }
              // }

              
              console.log("this is FB id ",planid);


              // const sourcePath1 = `programs/${patientData['Template_id']}/exercises`;
              console.log("check point 11");
              // const sourcePath1 = `B2Bprograms/${planid}/exercises/day1`;         // this is for prod
              // const sourcePath1 = `programs/${Data['FBTemplateid']}/exercises`;        
              console.log("check point 12");

              const sourceCollectionRef1 = doc(firestore, excercise_path);
              console.log("check point 13");

              const excersicedata = await getDoc(sourceCollectionRef1);
              console.log("check point 14", excersicedata.data());

              // console.log("THIS IS EX--------------------",excersicedata.data());

              const destinationPath1 = `${Data['Patient_idFP']}/programs/activeprograms/${Data['Template_id']}/exercises`;
              // const destinationPath1 = `9825187879/programs/activeprograms/${patientData['Template_id']}/exercises`;


              for (let day = 1; day <= patientData.duration; day++) {
                const exerciseDocRef = doc(firestore, `${destinationPath1}/day${day}`);
                await setDoc(exerciseDocRef, excersicedata.data());
              }

              // const sourcePath2 = `programs/${Data['Template_id']}/assessment`;
              // const sourcePath2 = `B2Bprograms/${planid}/assessment`;      // this is for prod 
              // const sourcePath2 = `programs/${Data['FBTemplateid']}/assessment`;      
              const sourceCollectionRef2 = collection(firestore, assessment_path);
              const assessmentdata = await getDocs(sourceCollectionRef2);
              console.log("this is checkpoint for assessment data ",assessmentdata.docs);

              const destinationPath2 = `${Data['Patient_idFP']}/programs/activeprograms/${Data['Template_id']}/assessment`;
              // const destinationPath2 = `9825187879/programs/activeprograms/${patientData['Template_id']}/assessment`;


              // assessmentdata.docs.forEach(async (docSnapshot) => {
              //   const assessmentData = docSnapshot.data();
              //   console.log("this is data is going to be added ",assessmentData)
              //   // Create a reference to the new path with a custom exercise ID (if needed)
              //   const customExerciseID = docSnapshot._key.path.segments[8]; // Implement your custom ID generation logic
              //   const exerciseDocRef = doc(firestore, destinationPath2, customExerciseID);
              //   console.log("this is assessment path reference ---------------------------",exerciseDocRef);
              //   // Add the exercise data to the new path
              //   try{
              //   await setDoc(exerciseDocRef, assessmentData);
              //   }catch(error){
              //     console.error("this is the errrorrr",error);
              //   }
              //   // await setDoc(exerciseDocRef, assessmentData);
              // }); 
              
              const aref = doc(firestore,`B2Bprograms/${planid}/assessment/assessment_0`);
              const asnapshot = await getDoc(aref);
              const adata = asnapshot.data();
              const activeProgramRef = doc(firestore, `${Data['Patient_idFP']}/programs/activeprograms/${Data['Template_id']}/assessment/assessment_0`);
              const updatedAData = {
                ...adata,
                Day_Assigned: "2",
              };

              await setDoc(activeProgramRef, updatedAData);
              const activeProgramRef2 = doc(firestore, `${Data['Patient_idFP']}/programs/activeprograms/${Data['Template_id']}/assessment/assessment_1`);
              const updatedAData2 = {
                ...adata,
                Day_Assigned: "15",
              };

              await setDoc(activeProgramRef2, updatedAData2);
              const activeProgramRef3 = doc(firestore, `${Data['Patient_idFP']}/programs/activeprograms/${Data['Template_id']}/assessment/assessment_2`);
              const updatedAData3 = {
                ...adata,
                Day_Assigned: "30",
              };

              await setDoc(activeProgramRef3, updatedAData3);
            
          
         

          try {
            const physioId = ID; // Get the physio ID
            
              const collectionRef3 = collection(firestore, `Physio/${physioId}/patients`);
              await setDoc(doc(collectionRef3,Data['Patient_idFP']), Data);
              console.log("Patient data added successfully", JSON.stringify(collectionRef3));
          } catch (error) {
              console.error("Error adding patient data:", error);
          }  
          
          
          return 1;
        } catch (firebaseError) {
          console.error("checking whether it is coming in this catch ",firebaseError)
          if (firebaseError) {
              // Network issue, retry after a delay
              retryCount++;
              console.warn(`Firebase operation failed, retrying (${retryCount}/${maxRetries})`);
              await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
          } else {
              // Log or handle other types of errors
              console.error('Firebase operation failed with error:', firebaseError);
              break; // Break out of the loop if it's not a retryable error
          }

          return 0;
      }
    }

    if (retryCount === maxRetries) {
        console.error('Max retries reached, unable to complete Firebase operation.');
    }
  }
    

    async function fetchCompleteSlotData() {
      let physioId;
      try {
        physioId = ID;
        // physioId = "Kj0lEf5DevcAQQE9ZughmA==";
      } catch (error) {
        console.log("User Data is loading .... ");
        return null;
      }
    
      const documentPath = `Physio/${physioId}/Calendar_Storage/CompleteSlot`;
      const BookedSlots = `Physio/${physioId}/Calendar_Storage/Booked_slots`;
    
      try {
        const completeSlotRef = doc(firestore, documentPath);
        const completeSlotSnapshot = await getDoc(completeSlotRef);
    
        const BookedSlotRef = doc(firestore, BookedSlots);
        const BookedSlotSnapshot = await getDoc(BookedSlotRef);
        const combinedSlotData = {};
        
        if (BookedSlotSnapshot.exists() && completeSlotSnapshot.exists()){
          updateCompleteSlotData(BookedSlotSnapshot.data(), completeSlotSnapshot.data());
        }
        if (BookedSlotSnapshot.exists()) {
          const bookedSlotRawData = BookedSlotSnapshot.data();
          console.log("this is bookedslotrawdata ",bookedSlotRawData);
    
          // Format booked slot data and add to combinedSlotData
          for (const date in bookedSlotRawData) {
            const slots = Array.isArray(bookedSlotRawData[date])
              ? bookedSlotRawData[date]
              : [bookedSlotRawData[date]];
    
            slots.forEach((slot) => {
              const slotID = `booked_${date}_${slot}`;
              if (!combinedSlotData[date]) {
                combinedSlotData[date] = [];
              }
              combinedSlotData[date].push({
                ID: slotID,
                start: slot,
                end: moment(slot, 'HH:mm').add(30, 'minutes').format('HH:mm'),
              });
            });
          }
    
          console.log("Booked Slot data", JSON.stringify(combinedSlotData));
        } else {
          console.log("Booked Slot document does not exist.");
        }
    
        if (completeSlotSnapshot.exists()) {
          const completeSlotRef = doc(firestore, documentPath);
          const completeSlotSnapshot = await getDoc(completeSlotRef);
          const completeSlotData = completeSlotSnapshot.data();
          console.log("Complete Slot data ", JSON.stringify(completeSlotData));
    
          // Push complete slot data to combinedSlotData
          for (const date in completeSlotData) {
            if (!combinedSlotData[date]) {
              combinedSlotData[date] = [];
            }
            completeSlotData[date].forEach((slot) => {
              const slotID = `complete_${date}_${slot.start}`;
              combinedSlotData[date].push({
                ID: slot.ID,
                start: slot.start,
                end: slot.end,
              });
            });
          }
          console.log("this is combined slot data", JSON.stringify(combinedSlotData));
          const consultationData = await WeeklyUpcomingAppointmentsData();
          
          console.log("combined slot Data ", JSON.stringify(combinedSlotData));

          for (const consultation of consultationData) {
            const { ConsultationTime, ConsultationJoinLink, ConsultationDate, PatientName } = consultation;
            
            // Extract the date from ConsultationDate (e.g., "06/10/2023")
            const consultationDate = ConsultationDate.split(' ')[0];
            
            // Check if there is an array with this date in combinedSlotData
            if (combinedSlotData[consultationDate]) {
              // Iterate through the slots for this date
              for (const slot of combinedSlotData[consultationDate]) {
                // Check if the slot ID starts with "booked" and the start time matches ConsultationTime
                if (slot.ID.startsWith("booked") && slot.start === ConsultationTime) {
                  // Merge consultation details into the slot data
                  slot.ConsultationJoinLink = ConsultationJoinLink;
                  slot.Name = PatientName;
                }
              }
            }
          }
          
          console.log(combinedSlotData); // Updated combinedSlotData
          
          return combinedSlotData;
        } else {
          console.log("Complete Slot document does not exist.");
          return combinedSlotData; // Return combinedSlotData even if completeSlotData doesn't exist
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    }
    


    // function to update the complete slot along with booked one 

    async function updateCompleteSlotData(bookedSlotData, completeSlotData) {
      // Iterate through each date in booked slot data
      for (const date in bookedSlotData) {
        const bookedSlots = bookedSlotData[date];
        const completeSlots = completeSlotData[date];
    
        if (!completeSlots || !bookedSlots) {
          console.log(`Missing data for date: ${date}`);
          continue;
        }
    
        // Iterate through each booked slot for this date
        for (const bookedSlot of bookedSlots) {
          // Find the corresponding complete slots for this date
          const matchingCompleteSlots = completeSlots.filter((completeSlot) =>
            moment(completeSlot.start, 'HH:mm').isSameOrBefore(moment(bookedSlot, 'HH:mm')) &&
            moment(completeSlot.end, 'HH:mm').isAfter(moment(bookedSlot, 'HH:mm'))
          );
    
          if (matchingCompleteSlots.length === 0) {
            console.log(`Matching complete slots not found for booked slot ${bookedSlot}`);
            
            // Check if blocked slots already exist for this time range
            const isBlocked = completeSlots.some((slot) => {
              const slotStartTime = moment(slot.start, 'HH:mm');
              const slotEndTime = moment(slot.end, 'HH:mm');
              const bookedSlotTime = moment(bookedSlot, 'HH:mm');
              const bookedSlotEndTime = bookedSlotTime.clone().add(30, 'minutes');
    
              return (
                slotStartTime.isSame(bookedSlotTime) && slotEndTime.isSame(bookedSlotEndTime)
              );
            });
            continue;
          }
    
          // Sort matchingCompleteSlots by start time
          matchingCompleteSlots.sort((a, b) => moment(a.start, 'HH:mm').diff(moment(b.start, 'HH:mm')));
    
          // Iterate through matchingCompleteSlots and split as needed
          const slotsToAdd = [];
    
          for (const overlappingSlot of matchingCompleteSlots) {
            const completeSlotStartTime = moment(overlappingSlot.start, 'HH:mm');
            const completeSlotEndTime = moment(overlappingSlot.end, 'HH:mm');
            const bookedSlotTime = moment(bookedSlot, 'HH:mm');
            const bookedSlotEndTime = bookedSlotTime.clone().add(30, 'minutes');
    
            if (bookedSlotTime.isSame(completeSlotStartTime)) {
              // Booked slot starts at the same time as complete slot
              // Create a new complete slot for the second part
              slotsToAdd.push({
                ID: bookedSlotEndTime.format('HH:mm') + overlappingSlot.end + date,
                start: bookedSlotEndTime.format('HH:mm'),
                end: overlappingSlot.end,
              });
            } else if (bookedSlotTime.isAfter(completeSlotStartTime)) {
              // Booked slot starts after the complete slot
              // Create the first part of the split complete slot
              slotsToAdd.push({
                ID: overlappingSlot.start + bookedSlotTime.format('HH:mm') + date,
                start: overlappingSlot.start,
                end: bookedSlotTime.format('HH:mm'),
              });
    
              // Create the second part of the split complete slot if needed
              if (bookedSlotEndTime.isBefore(completeSlotEndTime)) {
                slotsToAdd.push({
                  ID: bookedSlotEndTime.format('HH:mm') + overlappingSlot.end + date,
                  start: bookedSlotEndTime.format('HH:mm'),
                  end: overlappingSlot.end,
                });
              }
            }
          }
          const slotsToAdd1 = slotsToAdd.filter(item => item.start !== item.end);
          console.log("slot to add ",slotsToAdd1);
          // Remove the original complete slots that were split
          completeSlots.splice(
            completeSlots.indexOf(matchingCompleteSlots[0]),
            matchingCompleteSlots.length,
            ...slotsToAdd1
          );
        }
      }
    
      // Now, completeSlotData contains the updated data
    
      // Push the updated data back to Firebase here
      const physioId = ID;
      // const physioId = "Kj0lEf5DevcAQQE9ZughmA==";
      const documentPath = `Physio/${physioId}/Calendar_Storage/CompleteSlot`;
    
      try {
        const completeSlotRef = doc(firestore, documentPath);
        await setDoc(completeSlotRef, completeSlotData);
        console.log('Complete slot data updated successfully');
      } catch (error) {
        console.error('Error updating complete slot data:', error);
      }
    }
      
    // Usage
    // updateCompleteSlotData(bookedSlotData, completeSlotData);
    
    // async function AddNewSlot(slotdata) {
    //   const physioId = auth.currentUser.uid;
    
    //   // Prepare the new slot data
    //   const newSlot = {
    //     start: slotdata.start.toLocaleTimeString().substring(0, 5),
    //     end: slotdata.end.toLocaleTimeString().substring(0, 5),
    //     ID: slotdata.start.toLocaleTimeString().substring(0, 5)+slotdata.end.toLocaleTimeString().substring(0, 5)+slotdata.end.toLocaleDateString()
    //   };
    
    //   console.log("New Slot Data:", newSlot);

    //   const documentPath = `Physio/${physioId}/Calendar_Storage`;
      
    //   let isOverlap;
    
    //   try {
    //     // Check if there are existing slots for the specified date
    //     const completeSlotRef = doc(firestore, `${documentPath}/CompleteSlot`);
    //     // console.log("checkpoint 1 for adding ",completeSlotRef);
    //     const completeSlotSnapshot = await getDoc(completeSlotRef);
    //     // console.log("checkpoint 2 for adding ",completeSlotSnapshot);
    //     const existingSlots = completeSlotSnapshot.exists() ? completeSlotSnapshot.data() : {};
    //     // console.log("checkpoint 3 for adding ",existingSlots);

    //     const slotDate = slotdata.start.toLocaleDateString();
    
    //     if (existingSlots[slotDate]) {
    //       const existingDateSlots = existingSlots[slotDate];
    
    //       // Check for overlap with existing slots
    //       isOverlap = existingDateSlots.some((existingSlot) => {
    //         const existingStartTime = existingSlot.start;
    //         const existingEndTime = existingSlot.end;
    //         const newStartTime = newSlot.start;
    //         const newEndTime = newSlot.end;
    
    //         // Check if the new slot overlaps with the existing slot
    //         return (
    //           (newStartTime >= existingStartTime && newStartTime < existingEndTime) ||
    //           (newEndTime > existingStartTime && newEndTime <= existingEndTime)
    //         );
    //       });
    
    //       if (isOverlap) {
    //         console.log("Slot overlaps with existing slots. Not adding.");
    //         return 0;
    //       }
    
    //       // Add the new slot to the existing slots
    //       existingDateSlots.push(newSlot);
    
    //       // Update the Firestore document with the updated slots
    //       await setDoc(completeSlotRef, { ...existingSlots });
    //     } else {
    //       // No existing slots for the specified date, create a new entry
    //       existingSlots[slotDate] = [newSlot];
    
    //       // Update the Firestore document with the updated slots
    //       await setDoc(completeSlotRef, { ...existingSlots });
    //     }
    
    //     // Add intervals only if there is no overlap
    //     if (!isOverlap) {
    //       const startTime = new Date(slotdata.start);
    //       const endTime = new Date(slotdata.end);
    
    //       const timeIntervals = [];
    
    //       // Create 30-minute intervals between the start and end times
    //       const currentTime = new Date(startTime);
    //       while (currentTime < endTime) {
    //         timeIntervals.push(currentTime.toLocaleTimeString().substring(0, 5));
    //         currentTime.setMinutes(currentTime.getMinutes() + 30);
    //       }
    
    //       // Update the intervals for the specified date
    //       const availabilitySlotsRef = doc(firestore, `${documentPath}/availabilitySlots`);
    //       const availabilitySlotsSnapshot = await getDoc(availabilitySlotsRef);
    //       const existingIntervals = availabilitySlotsSnapshot.exists()
    //         ? availabilitySlotsSnapshot.data()
    //         : {};
    
    //       // Add the intervals to the existing intervals for the same date
    //       if (existingIntervals[slotDate]) {
    //         existingIntervals[slotDate].push(...timeIntervals);
    //       } else {
    //         existingIntervals[slotDate] = timeIntervals;
    //       }
    
    //       // Update the Firestore document with the updated intervals
    //       await setDoc(availabilitySlotsRef, { ...existingIntervals });
    
    //       console.log("Physio Slot and Intervals added successfully");
    //       return 1;
    //     }
    //   } catch (error) {
    //     console.error("Error adding or updating document:", error);

    //   }
    // }

    async function AddNewSlot(slotdata) {
      const physioId = ID;
      // const physioId = "SgBAqNW?Jzvf044fnOl+Rw==";
      console.log("this is slot data beforeee updatting it ------------------------- ",JSON.stringify(slotdata));
      // Prepare the new slot data
      let start = moment(slotdata.start).format('HH:mm');
      let end = moment(slotdata.end).format('HH:mm');

      const newSlot = {
        start,
        end,
        ID: start + end + moment(slotdata.end).format('DD/MM/YYYY'),
      };
        
      console.log("New Slot Data:-------------------", JSON.stringify(newSlot));
    
      const documentPath = `Physio/${physioId}/Calendar_Storage`;
      let isOverlap = false;
    
      try {
        const completeSlotRef = doc(firestore, `${documentPath}/CompleteSlot`);
        const completeSlotSnapshot = await getDoc(completeSlotRef);
        const existingSlots = completeSlotSnapshot.exists() ? completeSlotSnapshot.data() : {};
    
        const slotDate = slotdata.start.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        console.log("this is what creating issue ------------------------------",slotDate); 
        // Check for overlap with existing slots
        const updatedSlots = existingSlots[slotDate]?.map((existingSlot) => {
          const existingStartTime = existingSlot.start;
          const existingEndTime = existingSlot.end;
          const newStartTime = newSlot.start;
          const newEndTime = newSlot.end;
    
          // Check if the new slot overlaps with the existing slot
          if (
            (newStartTime >= existingStartTime && newStartTime < existingEndTime) ||
            (newEndTime > existingStartTime && newEndTime <= existingEndTime)
          ) {
            // Merge the new slot with the existing slot
            isOverlap = true;
            const mergedSlot = {
              start: newStartTime < existingStartTime ? newStartTime : existingStartTime,
              end: newEndTime > existingEndTime ? newEndTime : existingEndTime,
              ID: newSlot.ID, // Use the ID from the new slot
            };
            console.log("Slot merged with existing slot:", JSON.stringify(mergedSlot));
            return mergedSlot;
          }
    
          return existingSlot;
        });
    
        if (isOverlap) {
          // Update the Firestore document with the updated slots
          console.log("the final data which is being pushed ",JSON.stringify(updatedSlots));
          await setDoc(completeSlotRef, { ...existingSlots, [slotDate]: updatedSlots });
    
          console.log("Slot merged with existing slots. when there is over lap");
          // return 1; // Return 1 for overlap
        } else {
          // If there is no overlap, add the new slot as is
          if (!existingSlots[slotDate]) {
            existingSlots[slotDate] = [];
          }
          existingSlots[slotDate].push(newSlot);
          console.log("the final data which is being pushed ",JSON.stringify(existingSlots));
          // Update the Firestore document with the updated slots
          await setDoc(completeSlotRef, { ...existingSlots });
    
          console.log("New slot added successfully.");
        }
    
        const availabilitySlotsRef = doc(firestore, `${documentPath}/availabilitySlots`);
        const availabilitySlotsSnapshot = await getDoc(availabilitySlotsRef);
        const existingIntervals = availabilitySlotsSnapshot.exists() ? availabilitySlotsSnapshot.data() : {};

        // Generate intervals based on the new slot
        const startTime = moment(newSlot.start, 'HH:mm');
        const endTime = moment(newSlot.end, 'HH:mm');
        const timeIntervals = [];

        while (startTime.isBefore(endTime)) {
          timeIntervals.push(startTime.format('HH:mm'));
          startTime.add(30, 'minutes');
        }

        // Fetch existing slots for the specified date
        const existingSlots1 = existingIntervals[slotDate] || [];
        console.log("the slots which are existing 1 ---------",existingSlots1);
        // Combine intervals and existing slots, then remove duplicates
        const mergedSlots = [...timeIntervals, ...existingSlots1];
        console.log("the merged slot array -----------",mergedSlots);
        const uniqueMergedSlots = Array.from(new Set(mergedSlots));
        console.log("interval to be pushed -----------------------",uniqueMergedSlots);
        // Update the Firestore document with the updated intervals
        await setDoc(availabilitySlotsRef, { ...existingIntervals, [slotDate]: uniqueMergedSlots });

        console.log("Physio Slot and Intervals added successfully");
        return 1; // Return 1 for success

      } catch (error) {
        console.error("Error adding or updating document:", error);
        return 0; // Return 0 to indicate an error
      }
    }

    
    

    //Function for deleting Slot from Calender 

    async function deleteSlot(Event) {
      const physioId = ID;
      console.log("Event in auth user context to delete ",JSON.stringify(Event))
      const start1 = Event.ID.substring(0,5);
      const end1 = Event.ID.substring(5,10);
      // const physioId = "Kj0lEf5DevcAQQE9ZughmA==";
      const documentPath = `Physio/${physioId}/Calendar_Storage`;
    
      try {
        const completeSlotRef = doc(firestore, `${documentPath}/CompleteSlot`);
        const completeSlotSnapshot = await getDoc(completeSlotRef);
        if (!completeSlotSnapshot.exists()) {
          return 0;
        }
    
        const existingSlots = completeSlotSnapshot.data();
    
        // Iterate through each date in existingSlots
        for (const date in existingSlots) {
          const slots = existingSlots[date];
    
          // Filter slots to exclude the one with the specified slotID
          console.log("here is where code strugglesss slot.ID and Event.ID check for ID in both ", JSON.stringify(slots), Event.ID)
          const updatedSlots = slots.filter((slot) => slot.ID !== Event.ID);
    
          // Update the existingSlots with the filtered slots
          existingSlots[date] = updatedSlots;
        }
    
        // Update the Firestore document with the updated slots
        await setDoc(completeSlotRef, { ...existingSlots });
    
        // Now, remove the intervals associated with the deleted slot from availabilitySlots
        const availabilitySlotsRef = doc(firestore, `${documentPath}/availabilitySlots`);
        const availabilitySlotsSnapshot = await getDoc(availabilitySlotsRef);
        const existingIntervals = availabilitySlotsSnapshot.exists()
          ? availabilitySlotsSnapshot.data()
          : {};
        const slotDate = Event.start.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        const start = moment(start1, 'HH:mm');
        const end = moment(end1, 'HH:mm');
        const check = moment(Event.endTime, 'HH:mm');
        const check2 = moment(Event.startTime, 'HH:mm');
        const timeIntervals = [];

        console.log("the value of start and end which is causing this isssue ",start1,start,end1,end);
        while (start.isBefore(end)) {
          console.log("start time to be pushed ",start);
          console.log("end time that is been checked ",end);
          timeIntervals.push(start.format('HH:mm'));
          start.add(30, 'minutes');
        }
        console.log("time intervalll -------------- in del",timeIntervals);
        const existingInterval = existingIntervals[slotDate];
        const timeIntervalsSet = new Set(timeIntervals);
        console.log("printing issue part -------------------- existingInterval and slot in del",existingInterval)
        var newtimesslot;
        newtimesslot = existingInterval.filter((slot) => {
          if(check === end && check2 === start){
            return timeIntervalsSet.has(slot);
          }else{
            return !timeIntervalsSet.has(slot);
          }
          // return timeIntervalsSet.has(slot) && slotTime.isSameOrAfter(start) && slotTime.isBefore(end); 
        });

        console.log("new intervals-------------------- in del",newtimesslot)
        // if (existingInterval && Array.isArray(existingInterval) && existingInterval.length > 0) {
        //   newtimesslot = existingInterval.filter((slot) => {
        //     return !timeIntervalsSet.has(slot);
        //   });
        //   // Rest of your code here
        // } else {
        //   console.log('existingInterval is not defined or empty:', existingInterval);
        //   // Handle the case where existingInterval is not as expected
        // }
        
        // Update the Firestore document with the updated intervals
        await setDoc(availabilitySlotsRef, { ...existingIntervals, [slotDate]: newtimesslot });
        return 1;
      } catch (error) {
        console.error("Error deleting slot:", error);
        return 0;
      }
    }
    

    
    


    // function to fetch Weekly Upcoming Appointments
    const WeeklyUpcomingAppointmentsData = async() => {
      const physioID = ID;
      // // console.log("entering backend function ",phy/sioID);
      // const physioID = "rdcVmOFx6Fz55VoESHl+7w==";
      // console.log("auth uid 0",physioID);
      try {
        // const collectionRef = collection(firestore, `/Physio/${physioID}/patients`);
        // const querySnapshot = await getDocs(collectionRef);
        // const profiles = querySnapshot.docs;

        // const patientsData = await Promise.all(profiles.map(async (profile) => {
        //   const data = profile._document.data.value.mapValue.fields;
        //   const patientData = {
        //     PatientName: data.PatientNameFP?.stringValue || 'NA',
        //     Patient_id: data.Patient_idFP?.stringValue || 'NA',
        //   };
        //   return patientData;
        // }));

        // const sessionDetails = [];

        // for (const patientData of patientsData) {
        //   const patientID = patientData.Patient_id;

        //   const Upcoming_SessionsRef = collection(firestore, `/Physio/${physioID}/Consultation_Services/Upcoming_Sessions/${patientID}`);
        //   // const Upcoming_SessionsRef = collection(firestore, `/Physio/${physioID}/Consultation_Services /Upcoming_Sessions/${patientID}`);
        //   const Upcoming_SessionsSnapshot = await getDocs(Upcoming_SessionsRef);
        //   const sessionData = Upcoming_SessionsSnapshot.docs.map((doc) => doc.data());

        //   // Store sessionData in the patientDetails dictionary with patientID as the key
        //   // console.log("session ",sessionData);
        //   if(sessionData.length!==0){
        //     for(const session of sessionData){
        //       // console.log("session",patientData.PatientName)
        //       sessionDetails.push({
        //         ...session,
        //         Name: patientData.PatientName,
        //         ID: patientID
        //       })
        //     }
            
        //   }
        // }

        // console.log("sessionDetails ");
        // console.log(sessionDetails);

        const Ref = doc(
          firestore,
          `/Physio/${physioID}/Consultation_Services/Upcoming_Sessions`
        );
        const docSnapshot = await getDoc(Ref);
        console.log("the patient video call history data ",docSnapshot.data());
        const transformedData = [];
        const data = docSnapshot.data();
        // Iterate through the original data object
        for (const id in data) {
          if (data.hasOwnProperty(id)) {
            // Get the array of objects for the current ID
            const records = data[id];

            // Iterate through the records for the current ID and add the "ID" property
            records.forEach((record) => {
              record.ID = id;
            });

            // Concatenate the records for the current ID to the transformed data array
            transformedData.push(...records);
          }
        }

        console.log("finaldata from ",transformedData);
        return transformedData;

      } catch (error) {
        console.error("Error fetching patient IDs:", error);
        return {};
      }

    }


    const WeeklyUpcomingAppointmentsDataforPatients = async() => {
      const physioID = ID;
      // const physioID = "Kj0lEf5DevcAQQE9ZughmA==";
      // console.log("auth uid 0",physioID);
      try {
        // const collectionRef = collection(firestore, `/Physio/${physioID}/patients`);
        // const querySnapshot = await getDocs(collectionRef);
        // const profiles = querySnapshot.docs;

        // const patientsData = await Promise.all(profiles.map(async (profile) => {
        //   const data = profile._document.data.value.mapValue.fields;
        //   const patientData = {
        //     PatientName: data.PatientNameFP?.stringValue || 'NA',
        //     Patient_id: data.Patient_idFP?.stringValue || 'NA',
        //   };
        //   return patientData;
        // }));

        // const sessionDetails = {};

        // for (const patientData of patientsData) {
        //   const patientID = patientData.Patient_id;

        //   // const Upcoming_SessionsRef = collection(firestore, `/Physio/${physioID}/Consultation_Services /Upcoming_Sessions/${patientID}`);
        //   const Upcoming_SessionsRef = collection(firestore, `/Physio/${physioID}/Consultation_Services/Upcoming_Sessions/${patientID}`);
        //   const Upcoming_SessionsSnapshot = await getDocs(Upcoming_SessionsRef);
        //   const sessionData = Upcoming_SessionsSnapshot.docs.map((doc) => doc.data());

        //   // Store sessionData in the patientDetails dictionary with patientID as the key
        //   if(sessionData.length!==0){
        //     sessionDetails[patientID] = {
        //       patientName: patientData.PatientName,
        //       patientID: patientData.Patient_id,
        //       sessions: sessionData,
        //     };
        //   }
        // }

        // console.log("sessionDetails");
        // console.log(sessionDetails);

        // return sessionDetails;

        const Ref = doc(
          firestore,
          `/Physio/${physioID}/Consultation_Services/Upcoming_Sessions`
        );
        const docSnapshot = await getDoc(Ref);
        console.log("the patient video call data in patientprofile page",docSnapshot.data());

        return docSnapshot.data();

      } catch (error) {
        console.error("Error fetching patient IDs:", error);
        return {};
      }

    }

    const formatDate = (date) => {
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    };

    // Function to convert a Firestore timestamp to a JavaScript Date
    const convertFirestoreTimestamp = (firestoreTimestamp) => {
      // Convert Firestore Timestamp to milliseconds
      console.log("the output is ",firestoreTimestamp);
      const milliseconds = firestoreTimestamp.toMillis();
      // Create a JavaScript Date object from the milliseconds
      return new Date(milliseconds);
    };

    //fetching video call history 
    // const VideoCallHistoryData = async () => {
    //   const physioID = auth.currentUser.uid;
    
    //   try {
    //     const collectionRef = collection(firestore, `/Physio/${physioID}/patients`);
    //     const querySnapshot = await getDocs(collectionRef);
    //     const profiles = querySnapshot.docs;

    //     const patientsData = await Promise.all(profiles.map(async (profile) => {
    //       const data = profile._document.data.value.mapValue.fields;
    //       const patientData = {
    //         PatientName: data.PatientNameFP?.stringValue || 'N/A',
    //         Patient_id: data.Patient_idFP?.stringValue || 'NA',
    //       };
    //       return patientData;
    //     }));
    //     // console.log("patient Data ",patientsData)
    //     const VideoCallHistoryRef = doc(firestore, `/Physio/${physioID}/Consultation_Services/Session_history`);
    //     const VideoCallHistorySnapshot = await getDoc(VideoCallHistoryRef);
    //     const VideoCallHistory = VideoCallHistorySnapshot.data();
    //     console.log("the data for Video Call",VideoCallHistory);
    
    //     // Create a new dictionary to store the modified data
    //     const modifiedVideoCallHistory = {};
    
    //     for (const key in VideoCallHistory) {
    //       if (Object.hasOwnProperty.call(VideoCallHistory, key)) {
    //         const item = VideoCallHistory[key];
    
    //         // Access OrderDate, SessionTime, and other properties for each item
    //         const orderDate = new Date(item.OrderDate.seconds * 1000 + item.OrderDate.nanoseconds / 1000000);
    //         const sessionTime = new Date(item.SessionTime.seconds * 1000 + item.SessionTime.nanoseconds / 1000000);
    //         const combinedDateTime = new Date(sessionTime);
    //         const Session_Time = `${sessionTime.getHours()}:${sessionTime.getMinutes()}`;
    //         const Session_Date = formatDate(combinedDateTime);
            
    //         const patientData = patientsData.find((patient) => patient.Patient_id === key);

    //         // Add the modified data to the new dictionary
    //         modifiedVideoCallHistory[key] = {
    //           ...item,
    //           Session_Date,
    //           Session_Time,
    //           PatientName: patientData ? patientData.PatientName : 'N/A',
    //         };
    //       }
    //     }
    //     console.log("output ",modifiedVideoCallHistory);
    //     // Return the modified dictionary
    //     return modifiedVideoCallHistory;
    //   } catch (error) {
    //     console.error("Error fetching patient IDs:", error);
    //     return {};
    //   }
    // };

    const VideoCallHistoryData = async () => {
      const physioID = ID;
      // const physioID = "rdcVmOFx6Fz55VoESHl+7w==";
      // console.log("auth value ",auth);
    
      try {
    
        // console.log("print the patientData",patientsData);
        const data = await WeeklyUpcomingAppointmentsDataforPatients();
        const Data = data;    // saving this data to push it in session history 
        console.log("this is weekly upcoming appointment Data ",data);

        const currentDate = new Date();
        const updatedData = {};

        const historyData = {};

        // Iterate through the original data
        for (const patientId in data) {
          if (data.hasOwnProperty(patientId)) {
            updatedData[patientId] = data[patientId].map(session => {
              const consultationDateParts = session.ConsultationDate.split('/');
              const consultationTimeParts = session.ConsultationTime.split(':');
              
              // Assuming that dateParts[0] is day, dateParts[1] is month, and dateParts[2] is year
              const year = parseInt(consultationDateParts[2]);
              const month = parseInt(consultationDateParts[1]) - 1; // Month is zero-based
              const day = parseInt(consultationDateParts[0]);
              const hours = parseInt(consultationTimeParts[0]);
              const minutes = parseInt(consultationTimeParts[1]) + 30;
              const sessionEnd = new Date(year, month, day, hours, minutes);
              console.log("this is session end time and current time and if value",sessionEnd,currentDate,sessionEnd<currentDate);
              // Check if the session has passed
              if (sessionEnd < currentDate) {
                // Update ConsultationCompleted to true
                session.ConsultationCompleted = true;
                if (!historyData[patientId]) {
                  historyData[patientId] = []; // Initialize an array if it doesn't exist
                }
        
                // Push the session into the history array
                historyData[patientId].push(session);
              }

              return session;
            });
          }
        }
        
        // You can access the updated data as 'data' and the completed sessions in 'completedSessions'
        console.log("Updated data: ------------------------- history data ", data,historyData);


        const docRef = doc(firestore, `/Physio/${physioID}/Consultation_Services/Upcoming_Sessions`); // Replace with your collection name

        try {
          await setDoc(docRef, data);
          console.log("Document successfully updated.");
        } catch (error) {
          console.error("Error updating document:", error);
        }

        const sessionHistoryRef = doc(firestore, `/Physio/${physioID}/Consultation_Services/Session_history`);
        const existingData = (await getDoc(sessionHistoryRef)).data();
        const updatedSessionHistory = { ...existingData, ...historyData }; // historyData contains the new session history
        await setDoc(sessionHistoryRef, updatedSessionHistory);
        
        console.log("Session history updated successfully.");
        console.log("this is all data ",updatedSessionHistory);

        const dataArray = [];

        for (const userId in updatedSessionHistory) {
          
            const userArray = updatedSessionHistory[userId].map(item => {
              const dateStr = item.ConsultationDate; // DD/MM/YYYY
              const timeStr = item.ConsultationTime; // HH:MM

              // Split the date string into day, month, and year
              const [day, month, year] = dateStr.split("/");

              // Split the time string into hours and minutes
              const [hours, minutes] = timeStr.split(":");

              // Create a JavaScript Date object
              const date = new Date(year, month - 1, day, hours, minutes);

              // Format the date as "HH:mm ddd, DD MMM YYYY"
              const Session_Time = date.toLocaleString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                weekday: "short",
                day: "numeric",
                month: "short",
                year: "numeric",
              });

              return { ID: userId, Session_Time, ...item };
            });

            dataArray.push(...userArray);
          
        }

        console.log("final data ==================",dataArray);

        return dataArray;
      } catch (error) {
        console.error('Error fetching patient IDs:', error);
        return {};
      }
    };
    

    // fetching pending record data

    const PendingReportData = async () => {
      const physioID = ID;
      // const physioID = "Kj0lEf5DevcAQQE9ZughmA==";
    
      try {
        const PendingReportsIDRef = doc(firestore, `/Physio/${physioID}/Consultation_Services/PendingReportsID`);
        // const PendingReportsIDRef = doc(firestore, `/Physio/${physioID}/Consultation_Services /PendingReportsID`);
        const PendingReportsIDSnapShot = await getDoc(PendingReportsIDRef);
        const PendingReportsIDData = PendingReportsIDSnapShot.data();
    
        console.log("this is data",PendingReportsIDData)
        
        return PendingReportsIDData;
        
      } catch (error) {
        console.error("Error fetching patient IDs:", error);
        return {};
      }
    };
    

    // Define the fetchPatientScore function
    const fetchPatientScore = async (id) => {
      const physio_ID = ID;
      const patientUid = id;
      const reportDocRef = doc(firestore, `${patientUid}/ProfileData`);

      try {
        const reportDocSnapshot = await getDoc(reportDocRef);
        if (reportDocSnapshot.exists()) {
          const programIDField = reportDocSnapshot._document.data.value.mapValue.fields;
          const programID = programIDField.DocumentID ? programIDField.DocumentID.stringValue : null;
          const scoreData = await getOverviewData(patientUid, programID);
          
          // console.log("Data for scores",scoreData)
          return scoreData;
        }
        return {
          GrandTotalScore: 0,
          AdherenceScore: 0,
          StreakScore: 0,
        };
      } catch (error) {
        console.error("Error getting report or program scores:", error);
      }
    };

    // function to check whether patient have any assigned program or not 
    const checkuser = async (code,ID) => {
      try {
        const patientsCollection = doc(firestore, "ExistingPatients/OldPatientList");
        const list1 = await getDoc(patientsCollection);
        console.log("the list value -----------------", list1.data());
        const listData = list1.data();
        const patientMobile = ID;
        var id = ID; // Default to the original ID value
      
        if (listData && listData?.ListedUser && listData?.ListedUser[patientMobile]) {
          id = listData?.ListedUser[patientMobile];
        } 
      
        var newID = 1;
        ID = code + ID;
        const newUserDoc = doc(firestore, `/newuserids/user_id`);
        const newUserData = await getDoc(newUserDoc);
        if (newUserData.exists()) {
          const Data = newUserData.data();
          console.log("this is the DATA ", Data, Data.uid, Data.uid[ID], ID);
          if (Data && Data.uid && Data.uid[ID]) {
            id = Data.uid[ID];
            newID = 0;
          }
        }
      
        console.log("the final ID ", id);
        
        const userprogram = await patientActiveProgram(id);
        console.log("the user program detials ", userprogram);
        
        if (userprogram.length === 0) {
          console.log("Condition 1: No program exist for the Patient ");
          if (newID == 1) {
            return { id: "createID", returnvalue: 0 };
          } else {
            return { id, returnvalue: 0 };
          }
        } else {
          const today = new Date();
      
          for (const program of userprogram) {
            console.log("checkpoint 1 ", program.enddate);
            if (program.enddate !== null && program.enddate.length !== 0) {
              const dateString = program.enddate;
              const dateParts = dateString.split(" ");
              const datePart = dateParts[0].split("/");
              const day = parseInt(datePart[0], 10);
              const month = parseInt(datePart[1], 10) - 1;
              const year = parseInt(datePart[2], 10);
              const endDate = new Date(year, month, day);
              console.log("checkpoint 2 ", endDate, today, endDate > today);
              
              if (endDate > today) {
                console.log("checkpoint 3 in the if of returning 1 endDate > today");
                return { id, returnvalue: 1 };  // Return immediately
              } else {
                return { id, returnvalue: 2 };  // Return immediately
              }
            } else {
              return { id, returnvalue: 1 };  // Return immediately
            }
          }
        }
        
        return { id, returnvalue: 0 };  // Default return value if no conditions match
      } catch (error) {
        console.error("Error Fetching user Program:", error);
        return 0;
      }      
    };
    

    // function to fetch Program Renewal Data both for home Page and its own page 
    const programRenewalData = async () => {
      const uid = ID;
      // const uid = "Kj0lEf5DevcAQQE9ZughmA==";
      var data = [];
      try {
        const patients = await getPhysioPatients();
        // console.log("inside PR fn",patients);
        for(const patient of patients){
          // console.log("patients ",patient);
          const profiledata = await getPatientsDetails(patient.Patient_idFP);
          // console.log("printing profile data of ",patient.Patient_idFP,profiledata)
          var currentDay;
          const patientProgram = await patientActiveProgram(patient.Patient_idFP);
          // console.log("patientProgram for this patient ",patient.Patient_idFP,patientProgram);
          if(profiledata.length !==0 && patientProgram.length!==0){
            // console.log("profile data ",profiledata);
            if(patientProgram[0].enddate){
              if((patientProgram[0].enddate).length>0){
                currentDay = profiledata.currentDay?.integerValue || "0";
                // console.log("END DATE FROM DATABSE ",patientProgram[0].enddate,patient.Patient_idFP)
                const dateString = patientProgram[0].enddate;
                const dateParts = dateString.split(" ");
                const datePart = dateParts[0].split("/");
                const day = parseInt(datePart[0], 10);
                const month = parseInt(datePart[1], 10) - 1; // Subtract 1 as months are zero-based (0-11)
                const year = parseInt(datePart[2], 10);
                const enddate = new Date(year, month, day);
                // console.log("this is the end date",enddate);
                const currentDate = new Date();
                const timeDifference = (enddate - currentDate) / (1000 * 60 * 60 * 24);
                // console.log("value of renewal Recommended outside ",currentDate, enddate, patientProgram[0].enddate);
                // console.log("this is time difference ",timeDifference, timeDifference <= 4,patientProgram[0].renewalRecommended.toLowerCase(),patient.Patient_idFP, )
                if(timeDifference <= 4 && patientProgram[0].renewalRecommended.toLowerCase() !=='true'){
                  // console.log("PRANAV WE ARE PUSHING DATA")
                  // console.log("value of renewal Recommended inside",currentDate, enddate);
                  data.push({
                    patientId: patient.Patient_idFP,
                    patientName: patient.PatientNameFP,
                    program: patientProgram[0].programName,
                    programID: patientProgram[0].ID,
                    side: patientProgram[0].sideAssigned,
                    enddate: patientProgram[0].enddate,
                    color: patientProgram[0].color,
                    currentDay: currentDay,
                    programDuration: patientProgram[0].duration,
                    renewalRecommended: patientProgram[0].renewalRecommended
                  });
                  // console.log("PRANAV DATA RIGHT NOW ",data);
                }
              }
              
            }
            
          }
        }
        console.log("this is output ",data);
        localStorage.setItem('ProgramRenewalData', JSON.stringify(data));
        return data;
      } catch (error) {
        console.error("Error Fetching Program Renewal Data :", error);
        return [];
      }
    };


    // this function will recommend to renewal the program for that user 
    const ProgramRenewal = async(ID, programID) => {
      console.log("this is renwwal function ")

      const renewalRecommendedPath = `RenewalRecommended/${ID}`;
      const activeProgramPath = `${ID}/programs/activeprograms/${programID}`;
      const renewalRecommendedDocRef = doc(firestore, renewalRecommendedPath);
      const activeProgramDocRef = doc(firestore, activeProgramPath);

      // const currentDate = FieldValue.serverTimestamp();

      await setDoc(renewalRecommendedDocRef, {
        PushDate: serverTimestamp()
      });

      await updateDoc(activeProgramDocRef, {
        renewalRecommended: "true",
      });

    }

    // request consultation Physio to Patients 

    const requestConsultation = async(id,name) => {
      const physioId = ID;
      // const physioId = "Kj0lEf5DevcAQQE9ZughmA==";
      try{
        const pdata = await fetchPhysioData();
        const pconsultationdataRef = collection(firestore, `${id}/ConsultationServices/Consultations`);
        const pconsultationdataSnapshot = await getDocs(pconsultationdataRef);
        console.log("pconsultationdataSnapshot ",pconsultationdataSnapshot.docs);
        const count = (pconsultationdataSnapshot.docs).length + 1;
        console.log("physio data ",pdata)
        console.log("in auth context file ",id);
        const fields = {
          ConsultationDate: null,
          PhysioID: physioId,
          PatientName: name,
          ConsultationTime: "",
          ConsultationCompleted: false,
          PatientJoint: "Shoulder",
          ConsultancyPrice: pdata.ConsultancyPrice,
          ConsultationJoinLink: "",
          ConsultationSessionDuration: 30,
          PhysioRequestedConsultation: true,
          ConsultationPaymentDone: false,
          ConsultationSessionCount: count,
          PhysioName: pdata.Name,
          OrderID: null,
          PaymentDate: null,
          BookingDate: null,
        }
        console.log("fields to be pushed ",fields);
        const collectionRef4 = doc(firestore, `${id}/ConsultationServices/Consultations/ConsultationSession_${fields.ConsultationSessionCount}`);
        await setDoc(collectionRef4, fields);
        const ConsultancyRequestPath = `Physio/${ID}/patients/${id}`;
        const ConsultancyRequestRef = doc(firestore, ConsultancyRequestPath);
        // Update AssignedProgramCount with the new count
        await updateDoc(ConsultancyRequestRef, {
          ConsultationRequested: true,
        });

      }catch(e){
        console.log("Error pushing request consultation data ",e);
      }
    }


    // checking whether physio exist during login time 
    const checkPhysioExist = async(number) => {
      try{
        console.log("this number is coming in checking function ",number);
        console.log("react app: inside context/authusercontext.js",firestore);
        const PhysioRef = collection(firestore, `/Physio`);
        console.log("react app: PhysioRef path ",PhysioRef);
        const PhysioSnapshot = await getDocs(PhysioRef);
        console.log("react app: PhysioSnapshot captured ",PhysioSnapshot);
        const PhysioData = PhysioSnapshot.docs;
        console.log("react app: this is physio Data",PhysioData);
        // u have to encyrpt number here to check
        // u have to encyrpt number here to check
        

        for (const object of PhysioData) {
          console.log("the value we are checking is and their number, given number  ", object?._document?.data?.value?.mapValue?.fields?.PhysioID?.stringValue,object?._document?.data?.value?.mapValue?.fields?.MobileNumber?.stringValue,number);
          if (object?._document?.data?.value?.mapValue?.fields?.MobileNumber?.stringValue === number) {
            console.log("it is inside the if of loop ");
            localStorage.setItem('ID', JSON.stringify(object?._document?.data?.value?.mapValue?.fields?.PhysioID?.stringValue));
            setID(object?._document?.data?.value?.mapValue?.fields?.PhysioID?.stringValue);
            // setID('9821053033');
            console.log("ID of Physio  ",ID);
            return 1;
          }
        }
        
        return 0;
      }catch(e){
        console.log("Error Checking Physio Exist or not",e);
      }
    }


    const physioOnboarding = async (data, phoneNumber, countryCode) => {
      console.log("this is the data coming to physioOnboarding form ", data, phoneNumber, countryCode);
      const {
        fullName,
        expertise,
        address,
        dob,
        email,
        education,
        registrationNumber,
        profilePicture,
        gender,
        experience,
        approxPatients,
      } = data;
    
      try {
        const physioIDRef = doc(firestore, 'Physio_IncrementalID', 'Physio_IncrementalID');
        const physioIDDoc = await getDoc(physioIDRef);
        console.log("the whole data ", physioIDDoc.data());
        let idCount = physioIDDoc.data().id_count;
        const country = countries.find(c => c.code.replace('+', '') === String(countryCode));
    
        // Generate the new ID
        const newID = idCount + 1;
        const physioID = newID;
    
        // Update the id and id_count variables in Firestore
        const updates = {
          [`id.${phoneNumber}`]: newID,
          id_count: newID
        };
        await updateDoc(physioIDRef, updates);
    
        // Define the Firestore document reference
        const physioRef = doc(firestore, `Physio/${physioID}`);
    
        let profilePhotoURL = '';
    
        if (profilePicture) {
          const storageRef = ref(storage, `/PhysioProfilePicture/${fullName}`);
          const snapshot = await uploadBytes(storageRef, profilePicture);
          profilePhotoURL = await getDownloadURL(snapshot.ref);
        }
    
        // Calculate validity date (current date + 3 months)
        const currentDate = new Date();
        const validityDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
    
        // Data to be stored in Firestore
        const physioData = {
          About: expertise || "",
          Address: address || "",
          AssignPrepaidProgram: false,
          AssignedProgramCount: "0",
          ConsultancyPrice: 499,
          ConsultationSessionDuration: 30,
          ConsultationVerification: false,
          DateOfBirth: dob || "",
          Education: education || "",
          Email: email || "",
          Experience: experience,
          Gender: gender,
          Language: ["English", "Hindi"],
          MobileNumber: phoneNumber || "",
          CountryCode: `+${countryCode || ""}`,
          CountryName: country.name || "",
          Name: fullName || "",
          PANNumber: "",
          PatientsTreated: approxPatients,
          PhysioID: String(physioID),
          ProfilePhotoURL: profilePhotoURL,
          Rating: 0,
          RegistrationDate: new Date().toLocaleDateString(),
          Specialist: "",
          Testimonials: "",
          Verified: false,
          customProgram: false,
          B2BConsultationVerification: false,
          registrationNumber: registrationNumber,
          validityDate: Timestamp.fromDate(validityDate),
          RenewalAllow: false
        };
    
        // Add data to Firestore
        await setDoc(physioRef, physioData);
    
        // Start background operations
        runBackgroundTasks(physioID);
    
        // Set ID in local storage and state
        localStorage.setItem('ID', physioID);
        setID(physioID);
    
        return 1; // Onboarding successful
      } catch (e) {
        console.log("Error Onboarding Physio", e);
        return 0; // Onboarding failed
      }
    };
    
    // Background task function
    const runBackgroundTasks = async (physioID) => {
      const collectionRef = collection(firestore, "/programsForHK");
      const querySnapshot = await getDocs(collectionRef);
      const programsArray = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.Program_name,
          templateId: data.Template_id,
        };
      });
    
      for (const programdata of programsArray) {
        const tempid = programdata.id;
        const name = programdata.name;
        const codeID = await getNextProgramCode();
    
        const programCode = {
          customProgram: false,
          physioID: String(physioID),
          templateID: tempid,
          programCode: codeID,
          programName: name,
          joint: extractJointName(tempid),
          active: true
        };
    
        console.log("program codes ", programCode);
    
        const programCodeRef = doc(firestore, "ProgramCodes", codeID);
        await setDoc(programCodeRef, programCode);
      }
    };
    

    function incrementId(currentId) {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let newId = currentId.split('');
      for (let i = newId.length - 1; i >= 0; i--) {
          let nextCharIndex = alphabet.indexOf(newId[i]) + 1;
          if (nextCharIndex >= alphabet.length) {
              newId[i] = alphabet[0];
          } else {
              newId[i] = alphabet[nextCharIndex];
              break;
          }
      }
      return newId.join('');
    }
    
    // Helper function to generate a random 6-digit alphanumeric ID
    const getNextProgramCode = async () => {
      try{
        const idDocRef = doc(firestore, 'ProgramCodes', 'IDTracker');
        const idDocSnapshot = await getDoc(idDocRef);

        let currentId = 'AAAA'; // Start from 'AAAA' if not present
        if (idDocSnapshot.exists()) {
          currentId = idDocSnapshot.data().currentId;
        }

        const nextId = incrementId(currentId);
        console.log("current ID and next ID", currentId,nextId);
        // Update the ID in Firestore
        await updateDoc(idDocRef, { currentId: nextId });

        return nextId;
      } catch (error) {
        console.error("Error getting or updating ProgramCode:", error);
        throw error; // Propagate the error to handle it where this function is called
      }
    }
    //helper functions for generating unique IDS 

    function extractJointName(moduleID) {
      const jointNames = ["hip", "knee", "shoulder", "ankle", "back", "neck"];
      if (moduleID === "b2bHipandKnee0002") {
        return "knee";
      }
      if (moduleID === "b2bHipandKnee0001") {
        return "hip";
      }
      return jointNames.find(joint => moduleID.toLowerCase().includes(joint)) || "";
    }
    
    
    const updatingHomePageData = async () => {
      try {
        const patientsData = await getPhysioPatients();
        const updatedPatientsData = await Promise.all(
            patientsData.map(async (patient) => {
                const patientId = patient.Patient_idFP;
                return {
                    ...patient,
                    PatientTotalScore: 0,
                };
            })
        );
        await updateHomePageData(updatedPatientsData);
        console.log("Updated patients data in userauthcontext:", updatedPatientsData);
      } catch (error) {
          console.error("Error in background update process:", error);
      }
    }

    const GetAllPhysioData = async () => {
      try {
          const physioID = ID;
          const homePageDataRef = doc(firestore, `Physio/${physioID}/HomePageData/PatientsList`);
          const homePageDataSnapshot = await getDoc(homePageDataRef);
          const homePageData = homePageDataSnapshot.data();
  
          // If data exists at the specified path, return it immediately
          if (homePageData && homePageData.patients && homePageData.patients.length > 0) {
              console.log("Found existing data at the specified path:", homePageData.patients);
  
              (async () => {
                const patientsData = await getPhysioPatients();
                const updatedPatientsData = await Promise.all(
                    patientsData.map(async (patient) => {
                        const patientId = patient.Patient_idFP;
                        console.log("calling aynsc calls for patients ",patientId);
                        const patientScore = await fetchPatientScore(patientId);
                        const program = await patientActiveProgram(patientId);
                        console.log("this is the patient score ", program, patientId, patientScore);
                        return {
                          ...patient,
                          PatientTotalScore: patientScore.GrandTotalScore,
                          active: program.length > 0 ? program[0].active : "False",
                        };
                    })
                );
    
                // Call updateHomePageData with updatedPatientsData
                await updateHomePageData(updatedPatientsData);
    
                console.log("Updated patients data in userauthcontext:", updatedPatientsData);
            })();
    
            // Return existing data immediately
            console.log("returning home page data immediately")
            return homePageData.patients;
            
          } 
            // If data doesn't exist, perform the operations and return the new data
            const patientsData = await getPhysioPatients();
            const updatedPatientsData = await Promise.all(
                patientsData.map(async (patient) => {
                    const patientId = patient.Patient_idFP;
                    console.log("calling aynsc calls for patients ",patientId);
                    const patientScore = await fetchPatientScore(patientId);
                    const program = await patientActiveProgram(patientId);
                    console.log("this is the patient score ", program, patientId, patientScore);
                    return {
                      ...patient,
                      PatientTotalScore: patientScore.GrandTotalScore,
                      active: program.length > 0 ? program[0].active : "False",
                    };
                })
            );

            // Call updateHomePageData with updatedPatientsData
            await updateHomePageData(updatedPatientsData);

            console.log("Updated patients data in userauthcontext:", updatedPatientsData);
            return updatedPatientsData;
          
      } catch (error) {
          console.error("Error fetching or updating patients data:", error);
          return []; // Return an error code or handle the error as needed
      }
  };
  

    const updateHomePageData = async(data) => {
      const physioID = ID;
      console.log("Updating the backend data for home page",physioID, data);
      try {
        // Obtain reference to the Firestore collection
        const homePageDataRef = doc(firestore, `Physio/${physioID}/HomePageData/PatientsList`);

        await setDoc(homePageDataRef, { patients: data });


        console.log("Data updated successfully in HomePageData/PatientsList");
      }catch (error) {
        console.error("Error updating HomePageData/PatientsList:", error);
      }
    }


    
    const tranferdata = async () => {
      try{
        const aref = doc(firestore,`/programs/b2bHipandKnee0002/assessment/assessment_0`);
        const asnapshot = await getDoc(aref);
        const adata = asnapshot.data();
        const activeProgramRef = doc(firestore, `/B2Bprograms/B2bKnee_001/assessment/assessment_0`);
        const updatedAData = {
          ...adata,
          Day_Assigned: 2,
        };

        await setDoc(activeProgramRef, updatedAData);
        const activeProgramRef2 = doc(firestore, `/B2Bprograms/B2bKnee_001/assessment/assessment_1`);
        const updatedAData2 = {
          ...adata,
          Day_Assigned: 15,
        };

        await setDoc(activeProgramRef2, updatedAData2);
        const activeProgramRef3 = doc(firestore, `/B2Bprograms/B2bKnee_001/assessment/assessment_2`);
        const updatedAData3 = {
          ...adata,
          Day_Assigned: 28,
        };

        await setDoc(activeProgramRef3, updatedAData3);

      }catch(e){
        console.log("this is the error ",e);
      }
    }

    const ADDingdata = async () => {
      try{
        const data = {
          "9545554316": "JiYuS9egV8V+IERcgAnzpg==",
          
        }
        const ListedUser = {
          ListedUser: data
        };
        const collectionRef4 = doc(firestore, '/ExistingPatients/OldPatientList');
        await setDoc(collectionRef4, ListedUser);
        console.log("work done check firebase")
      }catch(e){
        console.log("this is the error",e)
      }
    }


    const fetchCurrentVersion = async () => {
      try{
        const collectionRef4 = doc(firestore, '/BuildVersion/PhysioApp');
        const data = await getDoc(collectionRef4);
        console.log("work done check firebase", data.data());
        return data.data();
      }catch(e){
        console.log("this is the error",e)
      }
    }

    const addingPhysioAppVersion = async (version) => {
      try{
        const collectionRef = doc(firestore, `Physio/${ID}`);
        const data = await getDoc(collectionRef);
        // console.log("this is physio Data ", data.data());
        const fields = {
          ...data.data(),
          AppVersion: version,
        }
        await setDoc(collectionRef, fields);
        // console.log("this is to be pushed ",fields);
      }catch(e){
        console.log("Error While Pushing App Version ",e)
      }
    }



    
    async function AddNewSlotDoc(slotdata) {
      const physioId = ID;
      // const physioId = "SgBAqNW?Jzvf044fnOl+Rw==";
      try {
        console.log("this is slot data before updating it ------------------------- ", JSON.stringify(slotdata));
      
        console.log("lets check slotdata",slotdata.id, slotdata.title,  slotdata.start,new Date(slotdata.end).toISOString(), slotdata.days, slotdata.appointmentType, slotdata.duration)
        const physio_ID = ID;
        // Convert start and end times from ISO string to Date objects
        const startTime = new Date(slotdata.start);
        const endTime = new Date(slotdata.end);
        
        // Add 5.5 hours to both start and end times
        startTime.setHours(startTime.getHours() + 5, startTime.getMinutes() + 30);
        endTime.setHours(endTime.getHours() + 5, endTime.getMinutes() + 30);

        
        const url = `https://d14m49meqk42ly.cloudfront.net/dev/addBulkSlots`;
      
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
          },
          body: JSON.stringify({
            id: slotdata.id,
            title: slotdata.title,
            start: startTime.toISOString(), // Convert modified start time back to ISO string
            end: endTime.toISOString(), 
            days: slotdata.days,
            appointmentType: slotdata.appointmentType,
            duration: slotdata.duration,
            physio_ID: physio_ID
          })
        });
      
        console.log("this is response ", response);
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      
      } catch (error) {
        console.error('Error while updating slot data:', error);
      }
    }

    async function AddNewSingleSlot(slotdata) {
      const physioId = ID;
      // const physioId = "SgBAqNW?Jzvf044fnOl+Rw==";
      console.log("this is slot data beforeee updatting it ------------------------- ",JSON.stringify(slotdata));
      // Prepare the new slot data
      let start = moment(slotdata.start).format('HH:mm');
      let end = moment(slotdata.end).format('HH:mm');
      const durationMinutes = (moment(slotdata.end)).diff(moment(slotdata.start), 'minutes');


      const newSlot = {
        start,
        end,
        ID: start + end + moment(slotdata.end).format('DD/MM/YYYY'),
        appointmentType: slotdata.appointmentType,
        appointmentStartTime: slotdata.appointmentStartTime,
        day: slotdata.day,
        remaningMinutes: durationMinutes,
        slotDuration: slotdata.slotDuration,
      };
        
      console.log("New Slot Data:-------------------", JSON.stringify(newSlot));
    
      const documentPath = `Physio/${physioId}/DocsCalender`;
      let isOverlap = false;
    
      try {
        const completeSlotRef = doc(firestore, `${documentPath}/CompleteSlot`);
        const completeSlotSnapshot = await getDoc(completeSlotRef);
        const existingSlots = completeSlotSnapshot.exists() ? completeSlotSnapshot.data() : {};
    
        const slotDate = slotdata.start.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        console.log("this is what creating issue ------------------------------",slotDate); 
        // Check for overlap with existing slots
        const updatedSlots = existingSlots[slotDate]?.map((existingSlot) => {
          const existingStartTime = existingSlot.start;
          const existingEndTime = existingSlot.end;
          const newStartTime = newSlot.start;
          const newEndTime = newSlot.end;
    
          // Check if the new slot overlaps with the existing slot
          if (
            (newStartTime >= existingStartTime && newStartTime < existingEndTime) ||
            (newEndTime > existingStartTime && newEndTime <= existingEndTime)
          ) {
            // Merge the new slot with the existing slot
            isOverlap = true;
            const mergedSlot = {
              start: newStartTime < existingStartTime ? newStartTime : existingStartTime,
              end: newEndTime > existingEndTime ? newEndTime : existingEndTime,
              ID: newSlot.ID, // Use the ID from the new slot
            };
            console.log("Slot merged with existing slot:", JSON.stringify(mergedSlot));
            return mergedSlot;
          }
    
          return existingSlot;
        });
    
        if (isOverlap) {
          // Update the Firestore document with the updated slots
          console.log("the final data which is being pushed ",JSON.stringify(updatedSlots));
          await setDoc(completeSlotRef, { ...existingSlots, [slotDate]: updatedSlots });
    
          console.log("Slot merged with existing slots. when there is over lap");
          // return 1; // Return 1 for overlap
        } else {
          // If there is no overlap, add the new slot as is
          if (!existingSlots[slotDate]) {
            existingSlots[slotDate] = [];
          }
          existingSlots[slotDate].push(newSlot);
          console.log("the final data which is being pushed ",JSON.stringify(existingSlots));
          // Update the Firestore document with the updated slots
          await setDoc(completeSlotRef, { ...existingSlots });
    
          console.log("New slot added successfully.");
        }
        return 1; // Return 1 for success

      } catch (error) {
        console.error("Error adding or updating document:", error);
        return 0; // Return 0 to indicate an error
      }
    }



    async function fetchCompleteSlotDocData() {
      try {
        const physio_ID = ID;
        // const physio_ID = "9988776655";
        const url = `https://d14m49meqk42ly.cloudfront.net/dev/fetchSlotforPhysio`;

        const response = await fetch(url, {
          method: 'POST', // Changed to POST as per API requirement
          headers: {
            'Content-Type': 'application/json',
            'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
          },
          body: JSON.stringify({
            physio_ID: physio_ID
          })
        });
        console.log("this is response ",response);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const urlA = `https://d14m49meqk42ly.cloudfront.net/dev/fetchAppointmentSlotforPhysio`;

        const responseA = await fetch(urlA, {
          method: 'POST', // Changed to POST as per API requirement
          headers: {
            'Content-Type': 'application/json',
            'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
          },
          body: JSON.stringify({
            physio_ID: physio_ID
          })
        });
        console.log("this is response of appointment slot ",responseA);
        if (!responseA.ok) {
          throw new Error('Network response was not ok');
        }

        const dataA = await responseA.json();
        const AppointmentslotsData = dataA.body;

        console.log("this is appointment data ",AppointmentslotsData)
        const data = await response.json();
        const slotsData = data.body;
        console.log("this is slot data ",slotsData)

        const transformedData = {};

        // Iterate over each slot
        AppointmentslotsData.forEach(slot => {
          // Extract data from slot
          const {  serial_number, orderid, slotid, bookingdate, appointmentprice, appointmentdate, appointmenttime, duration, patientname, patientnumber, appointmenttype } = slot;
      
          // console.log("this is the data ",serial_number, orderid, slotid, bookingdate, appointmentprice, appointmentdate, appointmenttime, duration, patientname, patientnumber, appointmenttype)
          // Calculate end time by adding duration to appointmentstarttime
          const startTime = new Date(`${appointmentdate} ${appointmenttime}`);
          const endTime = new Date(startTime.getTime() + (duration) * 60000); // slotduration in minutes
      
          // Format end time as HH:mm
          const endHHMM = endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      
          // console.log("this is the end time ",endHHMM)
          // Create a new slot object with desired format
          const newSlot = {
              ID: `booked_${slotid}`,
              start: appointmenttime,
              end: endHHMM,
              appointmentType: appointmenttype,
              appointmentStartTime: appointmenttime,
              slotDuration: duration,
              patientname: patientname,
              patientnumber: patientnumber,
              orderid: orderid
          };
      
          // If the date already exists in the transformed data, push the slot to its array, otherwise create a new array with the slot
          if (transformedData[appointmentdate]) {
              transformedData[appointmentdate].push(newSlot);
          } else {
              transformedData[appointmentdate] = [newSlot];
          }
      });
      

        slotsData.forEach(slot => {
          // Extract date from slot data
          const { date, starttime, endtime, appointmenttype, appointmentstarttime, day, remainingminutes, slotduration, slot_id } = slot;

          if (remainingminutes > 0) {
            // Create a new slot object with desired format
            const newSlot = {
              ID: slot_id,
              start: appointmentstarttime,
              end: endtime,
              appointmentType: appointmenttype,
              appointmentStartTime: appointmentstarttime,
              day: day,
              remaningMinutes: remainingminutes,
              slotDuration: slotduration
            };
        
            // If the date already exists in the transformed data, push the slot to its array, otherwise create a new array with the slot
            if (transformedData[date]) {
              transformedData[date].push(newSlot);
            } else {
              transformedData[date] = [newSlot];
            }
          }
        });


        console.log("This is the response for API ", data);
        console.log("this is transformed data ",transformedData)
        return transformedData;
      } catch (error) {
        console.error('Error fetching slot data through API:', error);
      }

      

      
         
    }



    async function deleteDocSlot(Event) {
      // const physioId = Event.ID;
      console.log("this is id ",Event.ID)
      console.log("Event in auth user context to delete ",JSON.stringify(Event))
      const url = `https://d14m49meqk42ly.cloudfront.net/dev/deleteSlot`;

      try{
        const response = await fetch(url, {
          method: 'DELETE', // Use DELETE method for DELETE API
          headers: {
            'Content-Type': 'application/json',
            'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
          },
          body: JSON.stringify({
            ID: Event.ID
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("Response from delete API:", data);

        return 1; // Return success code or data if needed

      } catch (error) {
        console.error("Error deleting slot:", error);
        return 0;
      }
    }


    // function to update the complete slot along with booked one 

    async function handleAddDocAppointment(patientName, patientNumber, slot_ID) {
      const physio_ID = ID;

      const url = `https://d14m49meqk42ly.cloudfront.net/dev/bookAppointmentWP`;

      try{
        const response = await fetch(url, {
          method: 'POST', // Use DELETE method for DELETE API
          headers: {
            'Content-Type': 'application/json',
            'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
          },
          body: JSON.stringify({
            physio_ID,
            patientName,
            patientNumber,
            slot_ID
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("Response from add apointment API:", data);

        return 1; // Return success code or data if needed

      } catch (error) {
        console.error("Error deleting slot:", error);
        return 0;
      }
    }

    async function updateCompleteDocSlotData(slotData) {
      console.log("this is the slot data ",slotData);
      const id = slotData.id;
      const IDD = slotData.ID;
      const title = slotData.title;
      
      const slotDuration = slotData.slotDuration;
      const appointmentType = slotData.appointmentType;
      const physio_ID = ID;

      // Convert start and end times from ISO string to Date objects
      const startTime = new Date(slotData.start);
      const endTime = new Date(slotData.end);
      
      // Add 5.5 hours to both start and end times
      startTime.setHours(startTime.getHours() + 5, startTime.getMinutes() + 30);
      endTime.setHours(endTime.getHours() + 5, endTime.getMinutes() + 30);
      console.log("this is the whole body ",id,ID,title,startTime.toISOString(),endTime.toISOString(),slotDuration,appointmentType,physio_ID);
      const url = `https://d14m49meqk42ly.cloudfront.net/dev/updateSlot`;

      try{
        const response = await fetch(url, {
          method: 'POST', // Use DELETE method for DELETE API
          headers: {
            'Content-Type': 'application/json',
            'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
          },
          body: JSON.stringify({
            id: id, 
            ID: IDD, 
            title: title, 
            start: startTime.toISOString(), 
            end: endTime.toISOString(), 
            slotDuration: slotDuration, 
            appointmentType: appointmentType, 
            physio_ID: physio_ID
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("Response from delete API:", data);

        return 1; // Return success code or data if needed

      } catch (error) {
        console.error("Error deleting slot:", error);
        return 0;
      }
    }

    async function generateAppointmentPDF(filteredEvents, selectedDate) {
      const physio_ID = ID;
      const physio_data = await fetchPhysioData();
      const name = physio_data.Name;
    
      // Format the selectedDate
      const formattedDate = new Date(selectedDate).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    
      // Extract patients from filteredEvents and format the time
      const patients = filteredEvents.map(event => ({
        patientName: event.patientName,
        patientNumber: event.patientNumber,
        appointmentStartTime: new Date(`2000-01-01T${event.appointmentStartTime}`).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        }),
        slotDuration: event.slotDuration,
        appointmentType: event.appointmentType
      }));
    
      // Construct data object to send to the API
      const requestData = {
        patients: patients,
        date: formattedDate,
        doctorName: name
      };
    
      try {
        // Call the API to generate the PDF
        const response = await fetch('https://d14m49meqk42ly.cloudfront.net/dev/appointment-pdf', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorizationToken': '63z6dytPN9DrEmyf2cLc0SIhr4'
          },
          body: JSON.stringify(requestData)
        });
    
        // Parse the response JSON
        const data = await response.json();
        console.log("This is data ",data);
        const responseBody = JSON.parse(data.body);
        console.log("this is body ",responseBody);
        const pdfUrl = responseBody.url;
      
        // Return the URL of the generated PDF
        return pdfUrl;
      } catch (error) {
        console.error('Error generating PDF:', error);
        return null;
      }
    }
    

    const getExcerciseList = async(patientID, programID) =>{
      try{
        const path = `/${patientID}/programs/activeprograms/${programID}/exercises/day1`;
        const collectionRef = doc(firestore,path);
        const SnapShot = await getDoc(collectionRef);
        const excerciseData = SnapShot.data();
        return excerciseData;
      }catch(e){
        console.log("Error fetching Excersice list ",e);
      }
    }


    const fetchAvailableJoints = async() =>{
      try{
        const path = `/CustomModule/JointsEnabled`;
        const collectionRef = doc(firestore,path);
        const SnapShot = await getDoc(collectionRef);
        const Joints = SnapShot.data();
        return Joints;
      }catch(e){
        console.log("Error fetching Joint list ",e);
      }
    }


    const fetchAllExcercises = async() =>{
      try{
        const path = `/CustomModule/ExerciseList`;
        const collectionRef = doc(firestore,path);
        const SnapShot = await getDoc(collectionRef);
        const Joints = SnapShot.data();
        return Joints;
      }catch(e){
        console.log("Error fetching Joint list ",e);
      }
    }

    const addCustomModule = async(moduleName, selectedExercisesData, joint) => {
      const physio_ID = ID;
      try {
        console.log(moduleName, selectedExercisesData, joint);
    
        // Generate a unique ID with the format b2b+jointname+ID+random 4 digit number
        const generateID = () => {
          const prefix = "b2b";
          const random4DigitNumber = Math.floor(1000 + Math.random() * 9000); // Generates a random 4-digit number
          return `${prefix}${joint}${physio_ID}${random4DigitNumber}`;
        }
    
        const moduleID = generateID();
    
        console.log("Generated Module ID: ", moduleID);
    
        // Exercise names list
        const exerciseNamesList = new Set([
          "Bridge",
          "LegRaises", 
          "Leg Raises", 
          "SideWalk", 
          "MWalk", 
          "PivotedHeelRaise", 
          "CatStretch", 
          "BackExtension", 
          "HeadLift", 
          "Head Lift",
          "Bracing",
          "BracingWithShoulderExtension",  
          "Rhomboids",
          "NeckRetraction",
          "NeckFlexion",  
          "LowRow",
          "PronatedBracing",
          "PronatedBracingWithShoulderExtension",
          "SerratusAnterior"
        ]);
    
        // Extract names, reps, and weightage
        const names = selectedExercisesData.map(exercise => exercise.name);
        const reps = selectedExercisesData.map(exercise => exercise.reps);
        const weightage = selectedExercisesData.map(exercise => exercise.weightage);
    
        // Calculate the target score
        const targetScore = selectedExercisesData.reduce((acc, exercise) => {
          const { name, reps, weightage } = exercise;
          const multiplier = exerciseNamesList.has(name) ? 1 : 2;
          return acc + (multiplier * reps * weightage);
        }, 0);
    
        console.log("Names: ", names);
        console.log("Reps: ", reps);
        console.log("Weightage: ", weightage);
        console.log("Target Score: ", targetScore);
    
        const currentDate = new Date();
        // Create the object with the given values
        const customObject = {
          creationDate: currentDate,
          JointName: joint,
          Amount: "1250",
          Assignment_date: null,
          Assignment_id: null,
          Document_id: moduleID,
          Duration: "30",
          End_date: null,
          Hand_Assigned: null,
          Order_id: null,
          Patient_id: null,
          Payment_date: null,
          Payment_done: "False",
          Physio_id: [ID],
          Program_id: moduleID,
          Program_name: moduleName,
          Start_date: null,
          Template_id: moduleID,
          TherabandColor_Assigned: null,
          customName: {
            [physio_ID]: moduleName
          },
          customPricing: {
            [physio_ID]:"890"
          },
          renewalRecommended: "false"
        };
    
        console.log("Custom Object: ", customObject);
    
        // Add the object to the specified path in Firebase
        const customProgramPath = `/CustomPrograms/${moduleID}`;
        const customProgramRef = doc(firestore, customProgramPath);
        await setDoc(customProgramRef, customObject);

        // Update the physio data
        const physioPath = `/Physio/${physio_ID}`;
        const physioRef = doc(firestore, physioPath);
        const physioSnapshot = await getDoc(physioRef);
        const existingPhysioData = physioSnapshot.data();

        // Update customProgramID and customProgramPrice
        const updatedCustomProgramID = [...(existingPhysioData.customProgramID || []), moduleID];
        const updatedCustomProgramPrice = {
          ...(existingPhysioData.customProgramPrice || {}),
          [moduleID]: "890"
        };

        const physioField = {
          customProgram: "True",
          customProgramID: updatedCustomProgramID,
          customProgramPrice: updatedCustomProgramPrice,
        };
        await updateDoc(physioRef, physioField);

        // Update exercises for each day
        for (let day = 1; day <= 30; day++) {
          const exercisesPath = `/CustomPrograms/${moduleID}/exercises/day${day}`;
          const exercisesRef = doc(firestore, exercisesPath);
          await setDoc(exercisesRef, {
            name: names,
            reps: reps,
            weightage: weightage,
            targetScore: targetScore
          });
        }

        for (let day = 1; day <= 30; day++) {
          const exercisesPath = `/B2Bprograms/${moduleID}/exercises/day${day}`;
          const exercisesRef = doc(firestore, exercisesPath);
          await setDoc(exercisesRef, {
            name: names,
            reps: reps,
            weightage: weightage,
            targetScore: targetScore
          });
        }

        const programCode = {
          customProgram: false,
          physioID: String(physio_ID),
          templateID: moduleID,
          programCode: await getNextProgramCode(),
          programName: moduleName,
          joint: extractJointName(moduleID),
        }
        console.log("program codes data for custom Module ", programCode);
        const programCodeRef = doc(firestore, "ProgramCodes", programCode.programCode);
        await setDoc(programCodeRef, programCode);

      } catch(e) {
        console.log("Error Adding Custom Module", e);
      }
    };

    
    

    return (
        <UserAuthContext.Provider value={{
            ID,
            Events, 
            setEvent,
            DocEvents, setDocEvent,
            auth, 
            user, 
            setUser,
            loggedIn,
            result, setResult,
            consultation, setConsultation,
            docsCalenderBool, setDocsCalenderBool,
            showDocCalender, setShowDocCalender,
            verified, setVerified,
            setUpRecaptcha,
            signUp, 
            logIn,  
            logOut, 
            loggingIn, 
            fetchPhysioData,
            getPhysioPatients, 
            getPatientsDetails,
            patientActiveProgram,
            getAllPrograms,
            EncryptData11,
            // DecryptData11,
            addPatientData,
            getExerciseData,
            getOverviewData,
            getHistogramData,
            getPlan,
            fetchPatientScore ,
            AddNewSlot,
            fetchCompleteSlotData,
            deleteSlot,
            WeeklyUpcomingAppointmentsData,
            VideoCallHistoryData,
            PendingReportData,
            WeeklyUpcomingAppointmentsDataforPatients,
            checkuser,
            programRenewalData,
            ProgramRenewal,
            // fetchData,
            checkPhysioExist,
            requestConsultation,
            GetAllPhysioData,
            updateHomePageData,
            tranferdata,
            ADDingdata,
            getAssessmentData,
            fetchCurrentVersion,
            addingPhysioAppVersion,
            AddNewSlotDoc,
            fetchCompleteSlotDocData,
            deleteDocSlot,
            AddNewSingleSlot,
            handleAddDocAppointment,
            updateCompleteDocSlotData,
            generateAppointmentPDF,
            physioOnboarding,
            getExcerciseList,
            fetchAvailableJoints,
            fetchAllExcercises,
            addCustomModule,
            getProgramCodes,
            }}>
            {children}
        </UserAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(UserAuthContext);
}

export default UserAuthContextProvider; // Export the context provider
